import { baseServiceRequest } from "./baseService";
import { AxiosResponse } from "axios";
import { Prompt } from "@App/models/prompt";
import { EventUserPromptsRequest } from "./requests/prompts";

export const getPromptById = async (promptId: string) => {
  return (await baseServiceRequest({
    path: `prompts/${promptId}`,
    type: "get",
  })) as AxiosResponse<Prompt, any>;
};

export const updateEventUserPrompts = async (request: EventUserPromptsRequest): Promise<void> => {
  await baseServiceRequest({
    path: `/event/userPrompt`,
    type: "put",
    useAuthHeader: true,
    postData: request,
  });
};
