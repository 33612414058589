import "./MyReservations.scss";
import Table from "@Components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { QUERY_KEY_USER_RESERVATION_LIST } from "@App/constants/queryKeyConstants";
import { editReservationRequest, getUserReservations } from "@Api/reservation";
import {
  CommunityAssetReservation,
  ReservationWaiver,
} from "@App/models/communityAssetReservation";
import { formatDate } from "@Utils/date";
import ShrinkableList from "@Components/shrinkable-list/ShrinkableList";
import TagBubble from "@Components/tag-bubble/TagBubble";
import {
  findTagBubbleTypeById,
  TagBubbleTypeMapping,
} from "@Components/tag-bubble/types";
import { useState } from "react";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import SupportRequestModal from "@Components/modals/support-request-modal/SupportRequestModal";
import { customEventPublish } from "@Utils/utils";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";

export const ReservationStatusTags: TagBubbleTypeMapping[] = [
  {
    id: "Initiated",
    text: "Initiated",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
  {
    id: "Confirmed",
    text: "Confirmed",
    backgroundColor: "#BFE3BC",
    fontColor: "#313F30",
  },
  {
    id: "PendingReview",
    text: "Pending Review",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
  {
    id: "Rejected",
    text: "Rejected",
    backgroundColor: "#FFE5E5",
    fontColor: "#FA3E3E",
  },
  {
    id: "OnHold",
    text: "On Hold",
    backgroundColor: "#D0F2FF",
    fontColor: "#1D345E",
  },
  {
    id: "Cancelled",
    text: "Canceled",
    backgroundColor: "#F1F3F5",
    fontColor: "#5F666D",
  },
  {
    id: "CancellationInitiated",
    text: "Cancellation Initiated",
    backgroundColor: "#F1F3F5",
    fontColor: "#5F666D",
  },
  {
    id: "Temporary",
    text: "Temporary",
    backgroundColor: "#FFE7B3",
    fontColor: "#725517",
  },
];

/**
 * Handling rendering shrinkable list of waivers if needed
 */
const renderWaivers = (waivers?: ReservationWaiver[]) => {
  if (!waivers || waivers.length === 0) {
    return (
      <span className={"flex justify-center"} style={{ color: "#454545" }}>
        None
      </span>
    );
  }

  const waiverComps = waivers.map((waiver, i) => (
    <span className={"flex justify-center"}>
      <a
        key={i}
        className="resy-table-cell-waiver display-block"
        target={"_blank"}
        rel={"noreferrer"}
        href={waiver.acceptedWaiverURL}
      >
        Waiver {waivers.length > 1 ? i + 1 : ""}
      </a>
    </span>
  ));
  return <ShrinkableList components={waiverComps} maxShown={2} />;
};

/**
 * Columns
 */
const columnHelper = createColumnHelper<CommunityAssetReservation>();

const MyReservations = () => {
  const [sendingEditRequest, setSendingEditRequest] = useState(false);
  const [editModalResy, setEditModalResy] = useState<
    CommunityAssetReservation | undefined
  >(undefined);

  const columns = [
    columnHelper.accessor("receiptId", {
      header: () => "Res. No.",
      size: 8,
    }),
    columnHelper.accessor("startDate", {
      header: () => "Date",
      cell: (info) => (
        <span>
          {formatDate(info.getValue(), "M/dd/yy h:mm a")}
          {" - "}
          {formatDate(info.row.original.endDate, "h:mm a")}
        </span>
      ),
      size: 12,
    }),
    columnHelper.accessor("amenity", {
      header: () => "Amenity or Facility",
      size: 15,
    }),
    columnHelper.accessor("venue", {
      header: () => "Venue",
      size: 15,
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => {
        const tagProps = findTagBubbleTypeById(
          ReservationStatusTags,
          info.getValue(),
        );
        return (
          <TagBubble
            text={info.getValue()}
            onClick={() => {}}
            itemId={info.row.original.id}
            className={"display-block"}
            backgroundColor={tagProps.backgroundColor}
            fontColor={tagProps.fontColor}
            fontWeight={400}
          />
        );
      },
      size: 12,
      meta: {
        centerAligned: true,
      },
    }),
    columnHelper.accessor("waivers", {
      header: () => "Waiver",
      cell: (info) => renderWaivers(info.getValue()),
      size: 12,
      meta: {
        centerAligned: true,
      },
    }),
    columnHelper.display({
      id: "edit",
      header: () => "Edit / Cancel",
      cell: (info) =>
        new Date().toISOString() <= info.row.original.endDate ? ( // ensure the resy hasnt ended
          <span
            className="resy-table-cell-pointer flex justify-center"
            onClick={(e) => {
              e.stopPropagation();
              setEditModalResy(info.row.original);
            }}
          >
            <ChatBubbleLeftIcon width={24} height={24} color={"#000000"} />
          </span>
        ) : (
          <span className="flex justify-center" style={{ color: "#454545" }}>
            Reservation complete
          </span>
        ),
      size: 6,
      meta: {
        centerAligned: true,
      },
    }),
  ];

  const onEditRequest = async (
    resyId: string,
    request: string,
    clearTextFunc: () => void,
  ) => {
    setSendingEditRequest(true);
    const response = await editReservationRequest(resyId, request);
    if (response.status >= 400) {
      customEventPublish(
        SHOW_RESULT_BANNER,
        `There was a problem submitting your request, please try again.`,
      );
    } else {
      customEventPublish(
        SHOW_RESULT_BANNER,
        `Your request has been submitted and you should hear from an admin soon!`,
      );
      clearTextFunc();
      setEditModalResy(undefined);
    }
    setSendingEditRequest(false);
  };

  return (
    <div id={"user-profile-my-reservations"}>
      <Table
        columns={columns}
        queryKey={[QUERY_KEY_USER_RESERVATION_LIST]}
        queryFn={getUserReservations}
        highlightOnHover
      />
      <SupportRequestModal
        title={"Reservation Edit / Cancel Request"}
        onRequest={(request, clearTextFunc) =>
          onEditRequest(editModalResy!.id, request, clearTextFunc)
        }
        onCancel={() => setEditModalResy(undefined)}
        isDisabled={sendingEditRequest}
        isVisible={!!editModalResy}
      >
        {editModalResy && (
          <div>
            <div className={"flex"}>
              <span className={"font-bold"}>Receipt ID:</span>
              &nbsp;
              <span>{editModalResy.receiptId}</span>
            </div>
            <div className={"flex"}>
              <span className={"font-bold"}>Date:</span>
              &nbsp;
              <span>
                {formatDate(editModalResy.startDate, "M/dd/yy h:mm a")}
                {" - "}
                {formatDate(editModalResy.endDate, "h:mm a")}
              </span>
            </div>
            <div className={"flex"}>
              <span className={"font-bold"}>Amenity:</span>
              &nbsp;
              <span>{editModalResy.amenity}</span>
            </div>
            <div className={"flex"}>
              <span className={"font-bold"}>Venue:</span>
              &nbsp;
              <span>{editModalResy.venue}</span>
            </div>
            <hr />
          </div>
        )}
      </SupportRequestModal>
    </div>
  );
};

export default MyReservations;
