import { useAuthUser } from "@App/hooks";
import { getUserKnockToken } from "@Api/notifications";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_KNOCK_USER_TOKEN } from "@App/constants/queryKeyConstants";

export default function useKnockAuth() {
  const { isUserLoggedIn, userData } = useAuthUser();

  // TODO: can move this to local storage and check expiration before querying; will need to replace useQuery tho
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY_KNOCK_USER_TOKEN],
    queryFn: getUserKnockToken,
    staleTime: (60 * 60 - 20) * 1000, // make sure to refresh before api key expires (sync timing with API)
  });

  if (!isUserLoggedIn || !userData?.id) {
    return {
      isLoggedIn: false,
    };
  }

  return {
    isLoggedIn: true,
    userId: userData!.id,
    knockAuth: data?.data,
    isLoadingKnockAuth: isLoading,
    isErrorKnockAuth: isError,
  };
}
