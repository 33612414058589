import {
  GenericPagedResponse,
  GenericPageItem,
} from "@App/models/BasePagedResponse";
import {
  AccessorKeyColumnDef,
  ColumnDef,
  PaginationState,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { UseTableQueryParams } from "@Components/table/types";
import { CellContext } from "@tanstack/table-core/src/core/cell";

export function unwrapPaginationRequest<TData>(
  data: GenericPagedResponse<TData> | undefined,
  page: number,
  pageSize: number,
  fillWithEmpties = false,
) {
  if (!data) return undefined;
  if (data.items.length < pageSize && page !== 1) {
    return {
      ...data,
      items: data.items.concat(
        fillWithEmpties
          ? Array(pageSize - data.items.length)
              .fill(null)
              .map(
                (_, index) =>
                  ({
                    id: `empty-${index}`,
                    empty: true,
                  }) as unknown as GenericPageItem<TData>,
              )
          : [],
      ),
    };
  }
  return data;
}

export const buildQueryFnParams = ({
  id,
  sortOptions,
  filterOptions,
  pageSize,
  pageIndex,
}: PaginationState &
  Pick<
    UseTableQueryParams<any, any, any>,
    "id" | "sortOptions" | "filterOptions"
  >) => ({
  id,
  body: {
    pageParams: {
      page: pageIndex,
      pageSize,
    },
    ...(!isEmpty(sortOptions) ? { sortOptions } : {}),
    filterOptions,
  },
});

export const buildCellContextForRowData = (row: any, property: string) =>
  // this is super hacky but it's a workaround to not using tanstack-table hooks in order to support exporting in background process
  // the column definitions that render the cell value will only use this, and they only use the `getValue()` and `row` properties
  // therefore we'll only add support for those for now, and will expand to support other properties if ever required
  ({
    row: {
      id: property,
      original: row,
    },
    getValue: () => row[property],
    renderValue: () => row[property],
  }) as CellContext<any, any>;

export function mapRowToExportObj<TData>(
  columns: ColumnDef<GenericPageItem<TData>, any>[],
  row: any,
) {
  // reduce columns and build an object of column-to-value mapping for a row entry
  return columns.reduce((rowObj: any, columnDef) => {
    // get accessor column properties
    const accessorColumnDef = columnDef as AccessorKeyColumnDef<TData>;
    const accessorKey = accessorColumnDef.accessorKey;

    // get the column header
    const columnHeader =
      typeof columnDef.header === "string"
        ? columnDef.header
        : (columnDef.header as any)();

    // verify it's an accessor column because we need the property to read off the row data
    if (!accessorColumnDef.accessorKey || typeof accessorKey !== "string") {
      throw new Error(
        `Column '${columnHeader}' is not a valid "accessor column" required for export`,
      );
    }

    // verify column header is a string
    if (!columnHeader || typeof columnHeader === "object") {
      throw new Error(
        `Column '${accessorKey}' header is not a supported type for export`,
      );
    }

    // build cell context for the row
    const cellContext = buildCellContextForRowData(row, accessorKey);

    // logic to choose serializable value:
    //   1. check if meta.exportValue is defined
    //   2. check if cell is string or cell func returns string
    //   3. check if getValue is string
    //   throw if none are valid

    // get export value if defined otherwise
    const exportVal = columnDef.meta?.exportValue?.(cellContext);

    // prettier-ignore
    let val =
            exportVal !== undefined
                ? exportVal
                : (
                    columnDef.cell && typeof columnDef.cell === "string"
                        // get cell property since it's a string
                        ? columnDef.cell
                        // otherwise get cell value which could be a non-serializable object (html) or undefined
                        : (columnDef.cell as any)?.(cellContext)
                );

    // if we still dont have a value or have an object, try to get the raw property value off the row
    if (val === undefined || typeof val === "object") {
      val = cellContext.getValue();
    }

    // check if the final value is defined and a serializable type (not an object)
    if (val === undefined || typeof val === "object") {
      throw new Error(
        `Tried to convert table column '${columnHeader}' to exportable value but no option available`,
      );
    }

    val =
      typeof val === "string"
        ? val
            // replace subsequent spaces, tabs, and newlines (created from ` usage in str); keep \r for explicit line breaks
            .replace(/(?:[ \t\n])+/g, " ")
            // replace all trailing spaces in "/r " with "/r"
            .replace(/\r\s/g, "\r")
            // remove starting/ending space
            .trim()
        : val;

    rowObj[columnHeader] = val;
    return rowObj;
  }, {});
}
