import "./ImageCarousel.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ImageCarouselProps } from "./ImageCarouselPropTypes";
import ImageCarouselLogic from "./ImageCarouselLogic";

const ImageCarousel = ({
  slides,
  onChange,
  onClickItem,
  onClickThumb,
  swipeable = true,
  emulateTouch = true,
  showIndicators = false,
  showStatus = false,
  showThumbs = false,
  infiniteLoop = true,
  useKeyboardArrows = true,
  customHeight,
  autoPlay = false,
  interval = 2000,
  showArrows = true,
  displaySingleImage = false,
  useDefaultLabel = false,
}: ImageCarouselProps) => {
  const props = ImageCarouselLogic(slides?.length);

  return (
    <div>
      <Carousel
        autoPlay={autoPlay}
        interval={interval}
        showArrows={showArrows}
        onChange={(index) => {
          props.setCurrentSlide(index);
          onChange && onChange(index);
        }}
        onClickItem={(index, item) => {
          onClickItem && onClickItem(index);
        }}
        onClickThumb={(index, item) => {
          onClickThumb && onClickThumb(index);
        }}
        swipeable={swipeable}
        emulateTouch={emulateTouch}
        showIndicators={showIndicators}
        showStatus={showStatus}
        showThumbs={!props.isMobileEmbed && showThumbs}
        infiniteLoop={infiniteLoop}
        useKeyboardArrows={useKeyboardArrows}
        selectedItem={props.currentSlide}>
        {slides?.map((slide, index) => {
          return displaySingleImage ? (
            <img
              key={`carousel_slide${index}`}
              className={"image-carousel-slide-image"}
              style={{
                height: customHeight,
              }}
              src={slide.image}
              alt=""
            />
          ) : (
            <div
              key={`carousel_slide${index}`}
              className={`image-carousel-slide ${
                !customHeight ? "image-carousel-slide-image-default" : ""
              }`}
              style={{
                backgroundImage: `url(${slide.image})`,
                height: customHeight,
                cursor: "pointer",
              }}>
              {useDefaultLabel &&
                (slides.length > 1 ||
                  slide.labelFirstElement ||
                  slide.labelSecondElement) && (
                  <div className={"image-carousel-slide-default-wrapper"}>
                    <div className={"image-carousel-slide-default"}>
                      <div className={"image-carousel-slide-default-title"}>
                        {slide.labelFirstElement}
                      </div>
                      <div className={"image-carousel-slide-default-subtitle"}>
                        {slide.labelSecondElement}
                      </div>

                      {slides.length > 1 && (
                        <div
                          className={"image-carousel-slide-dots"}
                          style={{
                            marginTop:
                              slide.labelFirstElement ||
                              slide.labelSecondElement
                                ? 10
                                : 0,
                          }}>
                          {props.slidesIterator.map((slideIndex) => {
                            return (
                              <div
                                key={`carousel_slide_dot${slideIndex}`}
                                className={`image-carousel-slide-dot ${
                                  slideIndex === index
                                    ? "image-carousel-slide-dot-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  props.setCurrentSlide(slideIndex);
                                }}></div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
