import { useTenantConfig } from "@App/hooks";
import { handleNotLoggedUserLogin } from "@App/store/actions/userActions";
import { Heart } from "@Components/icons";
import { Loader, Tag } from "@Components/index";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import "./EventItem.scss";
import EventItemLogic from "./EventItemLogic";
import { EventItemProps } from "./EventItemPropTypes";

const EventItem = ({
  event,
  isCard,
  isToday,
  ignoreAttending,
  notLoggedUser,
  showDateInformation,
  showCardRSVP,
  showTimeOnly,
  showDateOnly,
  isFetchingEvents,
}: EventItemProps) => {
  const {
    eventStartDate,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,
    areCommunityUserAccountsDisabled,
    updateEventPreference,
    detailsPath,
  } = EventItemLogic(event, isFetchingEvents);

  const placeholderUrl = useTenantConfig()?.features.defaultImagePlaceholder;

  return (
    <>
      <div
        className={`event-list-item ${isCard && "event-list-item-card"} ${
          isToday ? "event-list-item-today" : ""
        }`}
        style={{ flexDirection: isCard ? "column" : "row" }}
      >
        {event.eventType !== "Admin" && (
          <Link to={detailsPath} className={"event-list-item-image-wrapper"}>
            <Loader
              color={"silver"}
              className={"event-list-item-image-loader"}
            />
            {event.files && event.files?.length > 0 ? (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={event.files[0].url}
                alt=""
              />
            ) : (
              <img
                className={`event-list-item-image ${
                  isCard && "event-list-item-image-card"
                }`}
                src={placeholderUrl ?? ""}
                alt=""
              />
            )}
            {!notLoggedUser &&
              isCard &&
              !ignoreAttending &&
              (event.user?.isRSVPd || event.user?.isAttending) && (
                <div className={"event-list-item-image-tag"}>
                  <Tag
                    backgroundColor={"#CFF5D5"}
                    text={"Attending"}
                    isStatusTag={true}
                  />
                </div>
              )}
          </Link>
        )}

        <div
          className={`event-list-item-info ${
            isCard ? "event-list-item-info-card" : ""
          }`}
        >
          <Link
            type="text"
            to={detailsPath}
            className={`event-list-item-date-wrapper ${
              isCard ? "event-list-item-date-wrapper-card" : ""
            }`}
          >
            <div className={"event-list-item-date-time-datetime"}>
              {(showDateOnly || isCard || showDateInformation) &&
                !showTimeOnly && (
                  <div className={"event-list-item-date-time-month"}>
                    {format(eventStartDate, "MMM")}
                  </div>
                )}
              {(!showDateOnly && !isCard && !showDateInformation) ||
              showTimeOnly
                ? event.startDate === event.endDate
                  ? "All Day"
                  : format(eventStartDate, "h:mm").replace(":00", "")
                : format(eventStartDate, "d")}
              <div className={"event-list-item-date-time-ext"}>
                {(!showDateOnly && !isCard && !showDateInformation) ||
                showTimeOnly
                  ? event.startDate === event.endDate
                    ? "All Day"
                    : format(eventStartDate, "a")
                  : format(eventStartDate, "EEE")}
              </div>
            </div>
          </Link>

          <Link
            to={detailsPath}
            className={`event-list-item-details-wrapper ${
              isCard ? "event-list-item-details-wrapper-card" : ""
            }`}
          >
            {event?.title && (
              <div
                className={`event-list-item-name ${
                  isCard ? "event-list-item-name-card" : ""
                }`}
                style={{ alignItems: "center" }}
              >
                {event?.title}
              </div>
            )}
            {event.isPinned && (
              <Tag
                backgroundColor={"#ffd800"}
                text={"Signature Event"}
                size={"small"}
                isStatusTag={true}
                className={"event-list-item-pinned"}
              />
            )}
            {(showDateOnly || isCard || showDateInformation) &&
              !showTimeOnly && (
                <div className={"event-list-item-detail-text"}>
                  {event.startDate === event.endDate
                    ? "All Day"
                    : `${format(eventStartDate, "h:mm a")} - ${format(new Date(event.endDate), "h:mm a")}`}
                </div>
              )}
            {(event.venue || event.address?.address1) && (
              <span
                className={
                  "event-list-item-detail-text event-list-item-link event-list-item-location"
                }
              >{`${isCard ? "Location: " : ""}${
                event.venue || event.address?.address1
              }`}</span>
            )}
          </Link>
          <div
            className={`event-list-item-state-wrapper ${
              isCard && "event-list-item-state-wrapper-card"
            } ${
              !isCard &&
              notLoggedUser &&
              "event-list-item-state-wrapper-not-logged"
            }`}
          >
            {!notLoggedUser &&
              (event.user?.isRSVPd || event.user?.isAttending) &&
              !isCard && (
                <Tag
                  backgroundColor={"#CFF5D5"}
                  text={"Attending"}
                  isStatusTag={true}
                />
              )}

            {!notLoggedUser &&
              event.eventType !== "Admin" &&
              ((!ignoreAttending && !event.user?.isRSVPd) ||
                ignoreAttending) && (
                <button
                  className={
                    "event-list-item-fav" +
                    (isFavPreferenceProcessing
                      ? " event-list-item-fav-disabled"
                      : "")
                  }
                  style={{
                    marginTop: isFavPreferenceProcessing ? 5 : 0,
                  }}
                  disabled={isFavPreferenceProcessing}
                  onClick={() => {
                    updateEventPreference(
                      "isFavorite",
                      !event.user?.isFavorite,
                    );
                  }}
                >
                  {isFavPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    <Heart isActive={event.user?.isFavorite} />
                  )}
                </button>
              )}
            {!areCommunityUserAccountsDisabled &&
              (!isCard || showCardRSVP) &&
              event.eventType !== "Admin" &&
              event.isRsvpEnabled &&
              !event.user?.isRSVPd &&
              parseISO(event.startDate) > new Date() && (
                <button
                  className={
                    "event-list-item-state-button event-list-item-link"
                  }
                  style={{
                    marginBottom: isRsvpPreferenceProcessing ? 10 : 0,
                    marginRight: isRsvpPreferenceProcessing ? 20 : 0,
                  }}
                  disabled={isRsvpPreferenceProcessing}
                  onClick={() => {
                    if (notLoggedUser) {
                      handleNotLoggedUserLogin();
                    } else {
                      updateEventPreference("isRSVPd", !event.user?.isRSVPd);
                    }
                  }}
                >
                  {isRsvpPreferenceProcessing ? (
                    <Loader color={"silver"} width="20" height="10" />
                  ) : (
                    `${notLoggedUser ? "Login to Confirm" : "Confirm"}`
                  )}
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventItem;
