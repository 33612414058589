import "./EventTicketPurchase.scss";
import { Loader, Payments } from "@Components/index";
import { Navbar } from "@Layouts/index";
import EventTicketPurchaseLogic from "./EventTicketPurchaseLogic";

const EventTicketPurchase = () => {
  const {
    currentEvent,
    availableTickets,
    isLoadingTicketsData,
    isLoadingEvent,
    onPaymentSuccess,
  } = EventTicketPurchaseLogic();
  return (
    <Navbar>
      {isLoadingTicketsData || isLoadingEvent ? (
        <Loader modal text={"Loading event details"} />
      ) : (
        <div className={"event-ticket-purchase"}>
          <div className="event-ticket-purchase-content">
            {!currentEvent ? (
              <div className="event-ticket-purchase-no-event">
                No event found
              </div>
            ) : (
              <Payments
                items={availableTickets}
                //TODO: Refactor 'paymentData={{ ...props.currentEvent, type: "ticket" }}' to send the correct paymentData (not currentEvent) to match the rest of the things in the app.
                // Get in touch to the BE team and ensure we get prompts (with object array if needed, or string array if we are getting only de ids for the prompts)
                paymentData={{
                  ...currentEvent,
                  promptIds: currentEvent?.prompts?.map((prompt) => {
                    return { promptId: prompt };
                  }),
                  type: "ticket",
                }}
                availableTickets={availableTickets}
                onPaymentSuccess={onPaymentSuccess}
              />
            )}
          </div>
        </div>
      )}
    </Navbar>
  );
};

export default EventTicketPurchase;
