// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";
import "./NotificationPopover.scss";

import { config } from "@App/config/config";
import { useTenantConfig } from "@App/hooks";
import useKnockAuth from "@App/hooks/api/knock/useKnockAuth";
import useScreenView from "@App/hooks/helpers/useScreenView";
import KnockBell from "@Components/icons/knock-bell/KnockBell";
import { Loader, Menu } from "@Components/index";
import { NotificationFeedHeader } from "@Components/notifications-popover/feed-header/NotificationFeedHeader";
import { FeedItem } from "@knocklabs/client";
import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export type NotificationPopover = {
  onMobileActionTaken: () => void;
  onPopoverActivated: () => void;
};

const NotificationPopover = (props: NotificationPopover) => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);
  const navigate = useNavigate();
  const { isMobileView } = useScreenView();

  const {
    isLoggedIn,
    userId,
    knockAuth,
    isLoadingKnockAuth,
    isErrorKnockAuth,
  } = useKnockAuth();

  const appUrl = useTenantConfig()?.appUIBaseURL;

  const onNotificationClicked = (item: FeedItem) => {
    if (item.data?.link) {
      const isRelativeLink =
        appUrl && item.data.link.toLowerCase().startsWith(appUrl.toLowerCase());
      if (isRelativeLink) {
        navigate(
          item.data.link.toLowerCase().replace(appUrl.toLowerCase(), "/"), // set to local relative link
        );
      } else {
        const win = window.open(item.data!.link, "_blank");
        if (win != null) {
          win.focus();
        }
      }
    }
  };

  const handleBellClick = () => {
    !isVisible && props.onPopoverActivated();
    setIsVisible(!isVisible);
  };

  if (!isLoggedIn) {
    return <></>;
  }

  // render placeholder while loading or on error
  if (isErrorKnockAuth || isLoadingKnockAuth) {
    return (
      <div
        className={"notification-popover-placeholder"}
        onClick={() =>
          isMobileView ? props.onMobileActionTaken() : handleBellClick()
        }
      >
        <div className={"nav-button-icon-link"}>
          <KnockBell className="notification-popover-placeholder-bell" />
          <span className={"nav-button-icon-link-text"}>Notifications</span>
        </div>
        {isVisible && (
          <div className={"notification-popover-placeholder-modal"}>
            <Menu
              backgroundColor="#FFFFFF"
              fixedPosition={"right"}
              className={"notification-popover-placeholder-menu"}
            >
              {isErrorKnockAuth ? (
                <div>An error has occurred. Please try again.</div>
              ) : (
                <Loader color={"silver"} width="30" height="12" />
              )}
            </Menu>
          </div>
        )}
      </div>
    );
  }

  // render knock notifications when provided
  return (
    <KnockProvider
      apiKey={config.knockPublicApiKey}
      userId={userId}
      userToken={knockAuth?.token}
    >
      <KnockFeedProvider feedId={config.knockIntegrationChannelId}>
        <>
          <div
            className={"nav-button-icon-link"}
            onClick={() =>
              isMobileView ? props.onMobileActionTaken() : handleBellClick()
            }
          >
            <NotificationIconButton
              ref={notifButtonRef}
              onClick={() => setIsVisible(!isVisible)}
            />
            <span className={"nav-button-icon-link-text"}>Notifications</span>
          </div>
          <NotificationFeedPopover
            renderHeader={(props) => <NotificationFeedHeader {...props} />}
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
            onNotificationClick={onNotificationClicked}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default NotificationPopover;
