import "./Notifications.scss";
import { Navbar } from "@Layouts/index";
import { Loader } from "@Components/index";
import { KnockProvider } from "@knocklabs/react";
import useKnockAuth from "@App/hooks/api/knock/useKnockAuth";
import { config } from "@App/config/config";
import { useNavigate } from "react-router-dom";
import KnockNotificationsFeed from "@Components/notifications-feed/KnockNotificationsFeed"; /* TODO: Switch notification index for id when backend is connected */

const Notifications = () => {
  const navigate = useNavigate();

  const {
    isLoggedIn,
    userId,
    knockAuth,
    isLoadingKnockAuth,
    isErrorKnockAuth,
  } = useKnockAuth();

  if (!isLoggedIn || isErrorKnockAuth) {
    navigate("/");
    return <></>;
  }

  if (isLoadingKnockAuth) {
    return <Loader />;
  }

  return (
    <div className={"notifications-container"}>
      <Navbar>
        <div className={"notifications-section__list"}>
          <KnockProvider
            apiKey={config.knockPublicApiKey}
            userId={userId}
            userToken={knockAuth?.token}
          >
            <KnockNotificationsFeed />
          </KnockProvider>
        </div>
      </Navbar>
    </div>
  );
};

export default Notifications;
