import "./MyEvents.scss";
import Table from "@Components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { QUERY_KEY_USER_EVENT_LIST } from "@App/constants/queryKeyConstants";
import { editEventRequest, getUserEvents } from "@Api/event";
import { UserEvent } from "@App/models/user";
import { formatDate } from "@Utils/date";
import { useNavigate } from "react-router-dom";
import ShrinkableList from "@Components/shrinkable-list/ShrinkableList";
import { ChatBubbleLeftIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import SupportRequestModal from "@Components/modals/support-request-modal/SupportRequestModal";
import { customEventPublish } from "@Utils/utils";
import { SHOW_RESULT_BANNER } from "@App/constants/appConstants";

const MyEvents = () => {
  const navigate = useNavigate();
  const [sendingEditRequest, setSendingEditRequest] = useState(false);
  const [editModalEvent, setEditModalEvent] = useState<UserEvent | undefined>(
    undefined,
  );

  const columnHelper = createColumnHelper<UserEvent>();
  const columns = [
    columnHelper.accessor("startDate", {
      header: () => "Event Date",
      cell: (info) => <span>{formatDate(info.getValue(), "M/dd/yy")}</span>,
      size: 10,
    }),
    columnHelper.accessor("title", {
      header: () => "Event",
      size: 15,
    }),
    columnHelper.accessor("eventType", {
      header: () => "Type",
      size: 8,
    }),
    columnHelper.accessor("prompts", {
      header: () => "Prompts",
      cell: (prompts) => (
        <ShrinkableList
          components={prompts.getValue()?.map((p, i) => (
            <span key={i} className="display-block">
              {p}
            </span>
          ))}
          startHidden={true}
          maxShown={2}
        />
      ),
      size: 20,
    }),
    columnHelper.accessor("ticketsPurchased", {
      header: () => "No. of tickets",
      cell: (info) => (
        <span className={"flex justify-center"}>
          {info.row.original.eventType === "General" ? (
            <CheckIcon width={24} height={24} color={"#000000"} />
          ) : (
            info.getValue()
          )}
        </span>
      ),
      size: 10,
      meta: {
        centerAligned: true,
      },
    }),
    columnHelper.display({
      id: "edit",
      header: () => "Edit / Cancel",
      cell: (info) =>
        new Date().toISOString() <= info.row.original.endDate ? ( // ensure the event isnt over
          <span
            className="flex justify-center"
            onClick={(e) => {
              e.stopPropagation();
              setEditModalEvent(info.row.original);
            }}
          >
            <ChatBubbleLeftIcon
              className={"events-table-cell-details-clickable"}
              width={24}
              height={24}
              color={"#000000"}
            />
          </span>
        ) : (
          <span
            className="flex justify-center"
            style={{ color: "#454545", textDecoration: "none" }}
          >
            Event has ended
          </span>
        ),
      size: 10,
      meta: {
        centerAligned: true,
      },
    }),
    columnHelper.display({
      id: "seeDetails",
      header: () => "Details",
      cell: (info) => (
        <span
          className="events-table-cell-details-clickable flex justify-center"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/calendar/event-details/${info.row.original.eventId}`);
          }}
        >
          See Details
        </span>
      ),
      size: 12,
      meta: {
        disableLinking: true,
        centerAligned: true,
      },
    }),
  ];

  const onEditRequest = async (
    eventId: string,
    request: string,
    clearTextFunc: () => void,
  ) => {
    setSendingEditRequest(true);
    const response = await editEventRequest(eventId, request);
    if (response.status >= 400) {
      customEventPublish(
        SHOW_RESULT_BANNER,
        `There was a problem submitting your request, please try again.`,
      );
    } else {
      customEventPublish(
        SHOW_RESULT_BANNER,
        `Your request has been submitted and you should hear from an admin soon!`,
      );
      clearTextFunc();
      setEditModalEvent(undefined);
    }
    setSendingEditRequest(false);
  };

  return (
    <div id={"user-profile-my-events"}>
      <Table
        columns={columns}
        queryKey={[QUERY_KEY_USER_EVENT_LIST]}
        queryFn={getUserEvents}
        highlightOnHover
      />
      <SupportRequestModal
        title={"Event Edit / Cancel Request"}
        onRequest={(request, clearTextFunc) =>
          onEditRequest(editModalEvent!.eventId, request, clearTextFunc)
        }
        onCancel={() => setEditModalEvent(undefined)}
        isDisabled={sendingEditRequest}
        isVisible={!!editModalEvent}
      >
        {editModalEvent && (
          <div>
            <div className={"flex"}>
              <span className={"font-bold"}>Event:</span>
              &nbsp;
              <span>{editModalEvent.title}</span>
            </div>
            <div className={"flex"}>
              <span className={"font-bold"}>Event Date:</span>
              &nbsp;
              <span>{formatDate(editModalEvent.startDate, "M/dd/yy")}</span>
            </div>
            <div className={"flex"}>
              <span className={"font-bold"}>Tickets Purchased:</span>
              &nbsp;
              <span>
                {editModalEvent.eventType === "General" ? (
                  <CheckIcon width={24} height={24} color={"#000000"} />
                ) : (
                  editModalEvent.ticketsPurchased
                )}
              </span>
            </div>
            <hr />
          </div>
        )}
      </SupportRequestModal>
    </div>
  );
};

export default MyEvents;
