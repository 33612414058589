import { Chevron } from "@Components/icons";
import { format, isAfter, isSameDay, parseISO, startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import "./CalendarSelect.scss";

interface CalendarSelectProps {
  selected?: Date | null;
  availableDates?: string[];
  isVenuesView?: boolean;
  onMonthChange?: (month: Date) => void;
  onChange: (date: Date) => void;
  dataCy?: string;
}

const CalendarSelect = ({
  selected,
  availableDates,
  isVenuesView,
  onMonthChange,
  onChange,
  dataCy,
}: CalendarSelectProps) => {
  const renderDayContents = (day: number, date: Date) => {
    const tooltipText = `Tooltip for date: ${date}`;

    const yesterday = new Date();
    const yesterdaysDay = new Date().getDate() - 1;
    yesterday.setDate(yesterdaysDay);

    // If the current calendar date is after one day less than right now, it is valid.
    // TODO: Check if the hour will be a problem here.
    const isValidMonthDay = isAfter(date, yesterday);

    const calendarItem = availableDates?.find((availableDate) => {
      const availableDateAsDate = startOfDay(parseISO(availableDate));
      return isSameDay(availableDateAsDate, date);
    });

    return (
      <div
        className={`calendar-select-custom-day ${
          !isValidMonthDay ? "calendar-select-custom-day-invalid" : ""
        }`}
        title={tooltipText}
      >
        {day}
        {calendarItem && (
          <div className={"calendar-select-custom-day-marker"}></div>
        )}
      </div>
    );
  };

  return (
    <div className={"calendar-select"} data-cy={dataCy}>
      <DatePicker
        onChange={onChange}
        onMonthChange={(date) => {
          onMonthChange && onMonthChange(date);
        }}
        inline
        calendarClassName={`calendar-select-calendar ${
          isVenuesView ? "calendar-select-calendar__venues" : ""
        }`}
        renderDayContents={renderDayContents}
        selected={selected}
        disabledKeyboardNavigation={true}
        renderCustomHeader={({
          date: calendarDate,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div className={"calendar-select-custom-header"}>
            <div
              className={"calendar-select-custom-header-label"}
            >{`${format(calendarDate, "MMMM")} ${new Date(calendarDate).getFullYear()}`}</div>
            <div className={"calendar-select-custom-header-month-nav-wrapper"}>
              <button
                className={"calendar-select-custom-header-month-nav"}
                onClick={decreaseMonth}
              >
                <Chevron position={"left"} size={30} />
              </button>
              <button
                className={"calendar-select-custom-header-month-nav"}
                data-cy="calendar-select-month-forward-nav"
                onClick={increaseMonth}
              >
                <Chevron position={"right"} size={30} />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CalendarSelect;
