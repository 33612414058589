import { useEffect, useState } from "react";
import { Prompt } from "@App/models/prompt";
import { getPromptById } from "@App/api/prompts";
import { EventUserPromptsProps} from "@Components/event-user-prompts/EventUserPromptsTypes";
import { EventUserPromptResponse } from "@App/models/eventUserPromptResponse";
import { updateEventUserPrompts } from "@App/api/prompts";
import { useMutation } from "@tanstack/react-query";
import { EventUserPromptsRequest } from "@App/api/requests/prompts";

const EventUserPromptsLogic = (props: EventUserPromptsProps) => {
  const [promptsItems, setPromptItems] = useState<Prompt[]>([]);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);

  useEffect(() => {
    if (props.promptIds?.length) {
      setIsLoadingPrompts(true);

      const getPromptsAsync = async () => {
        let prompts: Prompt[] = [];

        for await (let prompt of props.promptIds!) {
          const response = await getPromptById(prompt);
          prompts.push(response.data);
        }

        return prompts;
      };

      getPromptsAsync().then((prompts) => {
        setPromptItems(prompts);
        setIsLoadingPrompts(false);
      });
    }
  }, [props.promptIds]);

  const updateEventUserPromptsMutation = useMutation({
    mutationFn: (request: EventUserPromptsRequest) => updateEventUserPrompts(request),
      
    onSettled: () => props.onContinue && props.onContinue(),
    onSuccess: () => {
      setIsLoadingPrompts(false);
    },
  });

  const handlePromptsClose = (promptResponses: EventUserPromptResponse[]) => {
    updateEventUserPromptsMutation.mutate({
      eventId: props.eventId,
      userId: props.userId,
      promptResponses
    });
  };

  return {
    promptsItems,
    isLoadingPrompts,
    handlePromptsClose
  };
};

export default EventUserPromptsLogic;