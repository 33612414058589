import { AmenityType, ListingCategory } from "@App/models/amenity";
import { AvailableRateAndTimesResponse } from "@App/models/AvailableRates";
import { PoiSearchResponse } from "@App/models/poi";
import { AxiosError, AxiosResponse } from "axios";
import { baseServiceRequest } from "./baseService";
import {
  AmenitiesData,
  GetAmenitiesRequest,
  GetMapCommunityAssetsRequest,
  UpdateAmenityUserPreferenceRequest,
} from "./requests/amenities";

export const getAmenities = async (params: GetAmenitiesRequest) => {
  return await baseServiceRequest<AmenitiesData>({
    path: `/communityAsset/Search`,
    type: "get",
    params,
  });
};

export const getListingCategories = async () => {
  return await baseServiceRequest<ListingCategory[]>({
    path: `/listingCategory`,
    type: "get",
  });
};

export const getAmenitiesDetails = async (amenityId: string) => {
  return await baseServiceRequest<AmenityType>({
    path: `/communityAsset/${amenityId}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const updateAmenityPreference = async (
  props: UpdateAmenityUserPreferenceRequest,
) => {
  return await baseServiceRequest({
    path: `/communityAsset/${props.amenityId}/user/${props.userId}`,
    type: "put",
    postData: props.preference,
    useAuthHeader: true,
  });
};

export const getMapCommunityAssets = async (
  requestParams: GetMapCommunityAssetsRequest,
): Promise<AxiosResponse<PoiSearchResponse, AxiosError>> => {
  return await baseServiceRequest({
    path: `/allAssets/map/Search`,
    type: "get",
    useAuthHeader: true,
    params: requestParams,
  });
};

export const getCommunityAssetAvailableDates = async (
  amenityId: string,
  startDate: string,
  endDate: string,
  assetPath?: string,
) => {
  const section = assetPath ? `/${assetPath}` : "";

  return await baseServiceRequest<{ dates: string[] }>({
    path: `/communityAsset/${amenityId}${section}/Availability/Search`,
    type: "get",
    useAuthHeader: true,
    params: { startDate, endDate },
  });
};

export const getCommunityAssetAvailableRatesAndTimes = async (
  amenityId: string,
  targetDate: string,
  assetPath?: string,
) => {
  const section = assetPath ? `/${assetPath}` : "";

  return await baseServiceRequest<AvailableRateAndTimesResponse>({
    path: `/communityAsset/${amenityId}${section}/Availability/TimeSlots`,
    type: "get",
    useAuthHeader: true,
    params: { targetDate },
  });
};

export const initiateVenueBookingPayment = async (data: any) => {
  return await baseServiceRequest({
    path: `/stripe/InitiatePaymentRequest`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};
