import {
  useKnockClient,
  useNotifications,
  useNotificationStore,
} from "@knocklabs/react";
import { config } from "@App/config/config";
import { Loader, NotificationItem, Tag } from "@Components/index";
import { useEffect, useState } from "react";
import { FilterStatus } from "@knocklabs/react-core";
import KnockBell from "@Components/icons/knock-bell/KnockBell";

const KnockNotificationsFeed = () => {
  const knockClient = useKnockClient();
  const feedClient = useNotifications(
    knockClient,
    config.knockIntegrationChannelId,
  );

  const { items, loading } = useNotificationStore(feedClient);
  const [status, setStatus] = useState(FilterStatus.All);

  useEffect(() => {
    feedClient.fetch({ status });
  }, [feedClient, status]);

  return (
    <section className={"notifications-section-wrapper"}>
      <div className={"notifications-section"}>
        <h1 className={"notifications-section__title"}>Notifications</h1>
        <div className={"notifications-section__header"}>
          <Tag
            text="All"
            onClick={() => setStatus(FilterStatus.All)}
            isSelected={status === FilterStatus.All}
          />
          <Tag
            text="Unread"
            onClick={() => setStatus(FilterStatus.Unread)}
            isSelected={status === FilterStatus.Unread}
          />
        </div>
        {loading && <Loader color="#000" />}
        {!loading && items.length === 0 && (
          <div className={"notifications-section__no-notis"}>
            <KnockBell
              color={"#494747"}
              className={"notifications-section__no-notis-bell"}
            />
            No {status == FilterStatus.Unread && "unread "}notifications
          </div>
        )}
        {!loading &&
          items.map((item) => (
            <NotificationItem
              feedClient={feedClient}
              key={item.id}
              notification={item}
            />
          ))}
      </div>
    </section>
  );
};

export default KnockNotificationsFeed;
