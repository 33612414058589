import { getPromptById } from "@App/api/prompts";
import { useQueries } from "@tanstack/react-query";

const usePrompts = (promptIds: string[]) => {
    return useQueries({
      queries: promptIds.map((id) => ({
        queryKey: ["prompts", id],
        queryFn: () => getPromptById(id),
        enabled: !!id,
        staleTime: Infinity,
      })),
    });
  };

  export default usePrompts;