import { AxiosGenericPagedResponse } from "@App/models/BasePagedResponse";

export const DEFAULT_RESIZE_SPEED = 20.0;

export type TableSortOptions<TData> = {
  [key in keyof TData]: boolean;
};

export type PageParams = {
  page: number;
  pageSize: number;
};

export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

export interface BaseTableRequest<
  TSortOptions extends TableSortOptions<any>,
  TFilterOptions,
> {
  id?: string;
  body: {
    pageParams: PageParams;
    sortOptions?: TSortOptions;
    filterOptions?: TFilterOptions;
  };
}

export interface UseTableQueryParams<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
> {
  queryFn: (
    request: BaseTableRequest<TSortOptions, TFilterOptions>,
  ) => Promise<AxiosGenericPagedResponse<TData>>;
  id?: string;
  sortOptions?: TSortOptions;
  filterOptions?: TFilterOptions;
}

export interface UsePagedTableQueryParams<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
> extends UseTableQueryParams<TData, TSortOptions, TFilterOptions> {
  queryKey: string[];
  pagination: PaginationState;
  enabled?: boolean;
}

export interface UseExportTableQueryParams<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
> extends UseTableQueryParams<TData, TSortOptions, TFilterOptions> {
  pageSize?: number;
  totalRowCount: number;
}
