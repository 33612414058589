import { getEventCategories } from "@App/api/event";
import { QUERY_KEY_EVENT_CATEGORY_LIST } from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";

const SIGNATURE_EVENT_NAME = "Signature Events";

export default function useEventCategories() {
  const { data: eventCategories, isLoading: isLoadingEventCategories } =
    useQuery({
      queryKey: [QUERY_KEY_EVENT_CATEGORY_LIST],
      queryFn: () => getEventCategories(),
      select: (data) => data.data,
      staleTime: Infinity,
    });

  // pull the signature events category to the front of the list
  const sortedCategories = eventCategories?.sort((a, b) =>
    a.name === SIGNATURE_EVENT_NAME
      ? -1
      : b.name === SIGNATURE_EVENT_NAME
        ? 1
        : 0,
  );

  return {
    eventCategories: sortedCategories,
    isLoadingEventCategories,
  };
}
