import userSession from "@App/auth/userSession";
import { ListingCategory } from "@App/models/amenity";
import { useEffect, useState } from "react";

const GenericListingLogic = (
  onSearch?: (text: string) => void,
  listingCategories?: ListingCategory[],
) => {
  const isMobileEmbed = userSession.isMobileViewMode;
  const [isListLoading, setIsListLoading] = useState(true);
  const [emptyListCounter, setEmptyListCounter] = useState(0);

  const incrementEmptyListCounter = () => {
    setEmptyListCounter((prevCount) => prevCount + 1);
  };

  const onSearchText = (searchText: string) => {
    setEmptyListCounter(0);
    onSearch && onSearch(searchText);
  };

  useEffect(() => setEmptyListCounter(0), [listingCategories]);

  return {
    isMobileEmbed,
    onSearchText,
    isListLoading,
    setIsListLoading,
    emptyListCounter,
    setEmptyListCounter,
    incrementEmptyListCounter,
  };
};

export default GenericListingLogic;
