import { config } from "@App/config/config";
import { Driving, Walking } from "@Components/icons";
import {
  Button,
  ImageFilesCarousel,
  Loader,
  Modal,
  PoiDetail,
} from "@Components/index";
import "mapbox-gl/dist/mapbox-gl.css";
import MapGl, { Layer, Popup, Source } from "react-map-gl";
import "./Map.scss";
import {
  clusterCountLayer,
  clusterLayer,
  POI_SOURCE_NAME,
  pulsingDotsLayer,
  routeLayer,
  unclusteredCirclePointLayer,
  unclusteredCirclePointSelectedLayer,
  unclusteredPointLayer,
  unclusteredPointSelectedLayer,
  userLocationLayer,
} from "./MapLayers";
import MapLogic from "./MapLogic";
import { MapProps } from "./MapPropTypes";

const Map = (componentProps: MapProps) => {
  const props = MapLogic(componentProps);

  if (!props.mapboxSettings?.accessToken) {
    return null;
  }

  return (
    <div className={"map-container"}>
      <MapGl
        ref={props.mapRefCallback}
        initialViewState={{
          latitude: props.mapboxSettings.latitude,
          longitude: props.mapboxSettings.longitude,
          zoom: props.mapboxSettings.zoom,
        }}
        mapboxAccessToken={props.mapboxSettings.accessToken}
        style={{
          height: componentProps.height ?? "100%",
          borderStyle: "solid",
          borderColor: "silver",
          borderWidth: componentProps.showBorder ? 1 : 0,
          minHeight: 200,
          borderRadius: 5,
        }}
        mapStyle={props.mapboxSettings.styleURL} //"mapbox://styles/cecilianpartners/cl836q1yq004q14nxejfx9stu"
        onLoad={() => {
          setTimeout(() => {
            props.setShowLoader(false);
          }, 1000);
        }}
      >
        {props.poiRoute && (
          <Source
            id={"route"}
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: props.poiRoute,
                  },
                },
              ],
            }}
          >
            <Layer {...routeLayer} />
          </Source>
        )}

        {props.showPopup && props.contextPoi && (
          <Popup
            longitude={props.contextPoi.address.geoCoordinates.longitude}
            latitude={props.contextPoi.address.geoCoordinates.latitude}
            closeOnClick={false}
            onClose={() => {
              props.setShowPopup(false);
            }}
            closeButton={true}
            style={{ maxWidth: "100%" }}
            focusAfterOpen={false}
          >
            <div
              onMouseMove={() => {
                props.cancelPopupClose();
              }}
              onMouseLeave={() => {
                if (!props.poiRoute) {
                  props.closePopup();
                }
              }}
            >
              <div className={"map-popup-image"}>
                {props.contextPoi.files && (
                  <ImageFilesCarousel
                    customHeight={"170px"}
                    files={props.contextPoi.files}
                  />
                )}
              </div>
              <div className={"map-popup-summary"}>
                <div className={"map-popup-summary-title"}>
                  {props.contextPoi.title}
                </div>
                {!!props.contextPoi.venue && (
                  <div>{`Venue: ${props.contextPoi.venue}`}</div>
                )}
                <div>{`Location: ${
                  props.contextPoi?.venue ?? props.contextPoi.address?.address1
                } ${props.contextPoi.address?.city}, ${
                  props.contextPoi.address?.state
                } ${props.contextPoi.address?.zip}`}</div>
                {props.poiNavigationDetails &&
                  props.poiNavigationDetails.id === props.contextPoi.id && (
                    <div>
                      <div>{`Distance: ${props.poiNavigationDetails.distance}`}</div>
                      <div className={"map-popup-summary-duration"}>
                        {`Duration: ${props.poiNavigationDetails.duration}`}
                        {config.defaultDirectionsTransport === "driving" ? (
                          <Driving />
                        ) : (
                          <Walking />
                        )}
                      </div>
                    </div>
                  )}
                {props.hasValidDetailsNavigation && (
                  <div className={"map-popup-summary-button"}>
                    <Button
                      text={"View More"}
                      onClick={() => {
                        props.onViewDetailClick();
                      }}
                      style={{ padding: "8px 20px 10px 20px", fontSize: 15 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Popup>
        )}

        <Source
          id={POI_SOURCE_NAME}
          type="geojson"
          data={props.poisGeojson}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...unclusteredPointLayer} />
          {!props.poiRoute && <Layer {...pulsingDotsLayer} />}
          {!props.poiRoute && <Layer {...clusterLayer} />}
          <Layer {...unclusteredCirclePointLayer} />

          {!props.poiRoute && <Layer {...clusterCountLayer} />}
        </Source>

        {props.selectedPoiSource && (
          <Source
            id={"poi_selected"}
            type="geojson"
            data={props.selectedPoiSource}
            cluster={false}
          >
            <Layer {...unclusteredCirclePointSelectedLayer} />
            <Layer {...unclusteredPointSelectedLayer} />
          </Source>
        )}

        <Source
          id={"user_location"}
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [
                    props.mapboxSettings.longitude,
                    props.mapboxSettings.latitude,
                    0.0,
                  ],
                },
              },
            ],
          }}
        >
          <Layer {...userLocationLayer} />
        </Source>
      </MapGl>
      {props.showMask && (
        <button
          onClick={() => {
            props.setShowMask(false);
          }}
          className={"map-container-mask"}
        ></button>
      )}

      <Modal isVisible={props.isPoiDetailsModalVisible}>
        {props.poiDetails && (
          <PoiDetail
            poi={props.poiDetails}
            onClose={() => {
              props.setIsPoiDetailsModalVisible(false);
            }}
            onRefreshData={() => {
              componentProps.onRefreshPois && componentProps.onRefreshPois();
            }}
          />
        )}
      </Modal>
      {props.showLoader && <Loader text={"Loading map, please wait"} modal />}
    </div>
  );
};

export default Map;
