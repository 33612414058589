import { useCallback, useEffect, useState } from "react";
import { Tab, TabsProps } from "./TabsPropTypes";
import { useSearchParams } from "react-router-dom";

const TabsLogic = (props: TabsProps) => {
  const [isAllSelected, setIsAllSelected] = useState(props.showAll);
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (tabs.length === 0 || props.dynamicTabs) {
      const tabElements = props.tabs.map((tab, index) => ({
        label: tab.label,
        index,
        value: tab.value,
        isActive: props.showAll ? false : tab.isActive,
        icon: tab.icon,
        isHidden: tab.isHidden,
        iconUrl: tab.iconUrl,
      }));
      setTabs(tabElements);
    }
  }, [props.tabs, props.dynamicTabs, props.showAll, tabs.length]);

  useEffect(() => {
    if (
      tabs &&
      searchParams.has("tab") &&
      !isNaN(parseInt(searchParams.get("tab")!))
    ) {
      handleSelectedTab(parseInt(searchParams.get("tab")!));
    }
  }, []);

  const handleSelectedTab = useCallback(
    (tabIndex: number) => {
      if (tabIndex !== -1) {
        setIsAllSelected(false);
      }
      setTabs((prevTabs) =>
        prevTabs.map((tab) => ({
          ...tab,
          isActive: tab.index === tabIndex,
        })),
      );

      // set the search parameters
      searchParams.set("tab", tabIndex.toString());
      setSearchParams(searchParams);

      props.onTabChange && props.onTabChange(tabIndex);
    },
    [props],
  );

  return { tabs, isAllSelected, handleSelectedTab, setIsAllSelected };
};

export default TabsLogic;
