import { FeedItem } from "@knocklabs/client";
import { FilterStatus, useTranslations } from "@knocklabs/react-core";
import React, { SetStateAction } from "react";

import { MarkAsRead } from "@knocklabs/react";
import { NotificationFeedDropdown } from "@Components/notifications-popover/feed-header/NotificationFeedDropdown";
import { useNavigate } from "react-router-dom";

export type NotificationFeedHeaderProps = {
  filterStatus: FilterStatus;
  setFilterStatus: React.Dispatch<SetStateAction<FilterStatus>>;
  onMarkAllAsReadClick?: (e: React.MouseEvent, unreadItems: FeedItem[]) => void;
};

const OrderedFilterStatuses = [
  FilterStatus.All,
  FilterStatus.Unread,
  FilterStatus.Read,
];

export const NotificationFeedHeader: React.FC<NotificationFeedHeaderProps> = ({
  onMarkAllAsReadClick,
  filterStatus,
  setFilterStatus,
}) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <header className="rnf-notification-feed__header">
      <div className="rnf-notification-feed__selector">
        <span className="rnf-notification-feed__type">
          {t("notifications")}
        </span>
        <span
          className={"rnf-notification-feed__type"}
          style={{ color: "#9ea0aa", cursor: "pointer" }}
          onClick={() => navigate("/notifications")}
        >
          View All
        </span>
      </div>
      <div style={{ marginLeft: "auto", marginRight: "20px" }}>
        <NotificationFeedDropdown
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value as FilterStatus)}
        >
          {OrderedFilterStatuses.map((filterStatus) => (
            <option key={filterStatus} value={filterStatus}>
              {t(filterStatus)}
            </option>
          ))}
        </NotificationFeedDropdown>
      </div>
      <MarkAsRead onClick={onMarkAllAsReadClick} />
    </header>
  );
};
