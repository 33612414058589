import { IconProps } from "@Components/icons/iconTypes";
import "./Chevron.scss";

interface ChevronProps extends IconProps {
  position: "left" | "up" | "right" | "down";
}

const Chevron = ({ color, size, position, className }: ChevronProps) => {
  return (
    <i
      className={`chevron-icon fa-solid fa-chevron-${position} ${className ?? ""}`}
      style={{ color: color, fontSize: size }}
    ></i>
  );
};

export default Chevron;
