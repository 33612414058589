import {
  AvailableRateAndTimes,
  AvailableRatesOption,
  EndTimeOption,
  StartTimeOption,
} from "@App/models/AvailableRates";
import { formatCurrency } from "@Utils/utils";
import { format, isSameMonth, parseISO } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type UseRateDropdownsParams = {
  rates?: AvailableRateAndTimes[];
  selectedRate?: AvailableRatesOption | null;
  selectedStartTime?: StartTimeOption | null;
  setSelectedDate: (date: Date) => void;
  setSelectedRate: (rate?: AvailableRatesOption) => void;
  setSelectedStartTime: (startTime?: StartTimeOption) => void;
  setSelectedEndTime: (endTime?: EndTimeOption) => void;
};

export const useRateDropdowns = ({
  rates,
  selectedRate,
  selectedStartTime,
  setSelectedDate,
  setSelectedRate,
  setSelectedStartTime,
  setSelectedEndTime,
}: UseRateDropdownsParams) => {
  const { t } = useTranslation();
  const [calendarSelectedMonth, setCalendarSelectedMonth] = useState(
    new Date(),
  );

  const handleMonthChange = (date: Date) => {
    const isCurrentMonth = isSameMonth(date, new Date());

    setCalendarSelectedMonth(date);
    resetDropdowns();

    const firstOfNewMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    setSelectedDate(isCurrentMonth ? new Date() : firstOfNewMonth);
  };

  const resetDropdowns = () => {
    setSelectedRate();
    setSelectedStartTime();
    setSelectedEndTime();
  };

  const handleRateChange = (rate: AvailableRatesOption) => {
    setSelectedRate(rate);
    setSelectedStartTime();
  };

  const handleStartTimeChange = (startTime: StartTimeOption) => {
    setSelectedStartTime(startTime);
    setSelectedEndTime();
  };

  const handleEndTimeChange = (endTime: EndTimeOption) => {
    setSelectedEndTime(endTime);
  };

  const getRateOptions = () => {
    if (!rates || rates?.length === 0) {
      return [];
    }

    const rateOptions: AvailableRatesOption[] = rates.map((rate) => {
      return {
        label: `${t(rate.rateName)} (${formatCurrency(rate.rate)}/${rate.rateType})`,
        value: rate.rateId,
        ...rate,
      };
    });
    return rateOptions;
  };

  const getStartTimes = () => {
    if (selectedRate?.potentialSlots?.length === 0) return [];
    const startTimesOptions = selectedRate?.potentialSlots.map((slot) => {
      return {
        label: format(parseISO(slot.startTime), "h:mm aaa"),
        value: slot.startTime,
        endTimes: slot.endTimes,
      };
    });
    return startTimesOptions ?? [];
  };

  const getEndTimes = () => {
    if (selectedStartTime?.endTimes?.length === 0) return [];
    const endTimesOptions: EndTimeOption[] | undefined =
      selectedStartTime?.endTimes.map(({ endTime, cost }) => {
        return {
          label: format(parseISO(endTime), "h:mm aaa"),
          value: endTime,
          cost,
        };
      });
    return endTimesOptions ?? [];
  };

  return {
    calendarSelectedMonth,
    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    getRateOptions,
    getStartTimes,
    getEndTimes,
    resetDropdowns,
  };
};
