import { domainMap, DomainMap, validTenantIds } from "@App/models/DomainMap";
import { config } from "@App/config/config";

// intentional design to not export this const
// all tenant storage manipulation should go through the functions in this file
const TENANT_ID_PROP = "cbpTenantId";

export function getTenantId(): string {
  let tenantId = getTenantIdFromStorage();
  if (tenantId) {
    return tenantId;
  }

  // get and store tenant by url
  storeTenantByUrl();

  // get tenant id that was stored
  tenantId = getTenantIdFromStorage();
  if (!tenantId) {
    throw new Error(`No tenant ID defined, required for all requests`);
  }
  return tenantId;
}

function getTenantIdFromStorage() {
  if (localStorage.getItem(TENANT_ID_PROP)) {
    // handle edge case where tenantId gets set to 'undefined' or 'null'
    if (!isValidTenantId(localStorage.getItem(TENANT_ID_PROP)!)) {
      deleteTenantFromStore();
      return undefined;
    }

    return localStorage.getItem(TENANT_ID_PROP)!;
  }
  return undefined;
}

export function isValidTenantId(tenantId: string) {
  return validTenantIds.indexOf(tenantId) > -1;
}

export function deleteTenantFromStore() {
  localStorage.removeItem(TENANT_ID_PROP);
}

export function storeTenantId(tenantId: string) {
  if (!isValidTenantId(tenantId)) {
    throw new Error(
      `Tried to store tenantId='${tenantId}' but it's not a valid tenantId`,
    );
  }
  localStorage.setItem(TENANT_ID_PROP, tenantId);
}

export function validateTenantStored() {
  return getTenantId();
}

export function storeTenantByUrl() {
  const url: URL = new URL(window.location.href);
  const matchedDomain: DomainMap | undefined = domainMap.find((x: DomainMap) =>
    url.hostname.toLowerCase().includes(x.domain.toLowerCase()),
  );

  if (!!matchedDomain) {
    storeTenantId(matchedDomain.tenantId);
  } else {
    if (config.environment === "local") {
      storeTenantId(config.cbpTenantId ?? "lakewoodranch");
    }
  }
}
