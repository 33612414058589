import { tenantConfigGet } from "@App/api/general";
import { getVerifiedSignInEmail } from "@App/api/user";
import { getTenantId } from "@App/auth/tenant";
import UserSession from "@App/auth/userSession";
import { config } from "@App/config/config";
import {
  REMEMBER_EMAIL,
  REMEMBER_EMAIL_RESET,
} from "@App/constants/appConstants";
import { QUERY_KEY_TENANT_CONFIG } from "@App/constants/queryKeyConstants";
import { Tenant } from "@App/models/tenant";
import { getUserDetails } from "@App/store/actions/userActions";
import * as Sentry from "@sentry/react";
import { setContext } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginProps } from "./LoginProps";

const LoginLogic = (props: LoginProps) => {
  const rememberEmail = localStorage.getItem(REMEMBER_EMAIL);
  const [rememberMyDetails, setRememberMyDetails] = useState(
    !!localStorage.getItem(REMEMBER_EMAIL),
  );
  const [showRememberMyDetails, setShowRememberMyDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] =
    useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: tenantConfig } = useQuery([QUERY_KEY_TENANT_CONFIG], () => {
    return tenantConfigGet().then((res) => res.data as Tenant);
  });

  if (rememberEmail) {
    const input = document.querySelector(
      '[data-amplify-authenticator-signin] [name="username"]',
    );
    if (input) {
      input.setAttribute("value", rememberEmail);
    }
  }

  useEffect(() => {
    if (UserSession.accessToken) {
      handleLoggedUser();
    }

    //https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
    Hub.listen("auth", ({ payload: { event, data } }) => {
      let error = "";
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setStatusMessages({ errorMessage: null, successMessage: null });
          UserSession.accessToken = data.signInUserSession.accessToken.jwtToken;
          UserSession.cognitoUsername = data.username;
          setContext("LOG DETAILS", {
            tags: {
              "cognito.username":
                UserSession.cognitoUsername ?? "Not Logged User",
              version: process.env.REACT_APP_VERSION,
            },
          });
          if (rememberMyDetails) {
            localStorage.setItem(REMEMBER_EMAIL, data.attributes.email);
          } else {
            localStorage.removeItem(REMEMBER_EMAIL);
          }
          handleLoggedUser();
          break;
        case "signOut":
          break;
        case "completeNewPassword_failure":
          break;
        case "forgotPassword":
          setStatusMessages({ errorMessage: null, successMessage: null });
          break;
        case "forgotPassword_failure":
          switch (data.code) {
            case "UserNotFoundException":
              error = "The email is not associated with an existing account.";
              break;
            case "LimitExceededException":
              error = `You've reached your maximum Forgot Password reset requests. Please contact ${
                tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
                config.defaultAdminEmail
              } for assistance.`;
              break;
            case "NotAuthorizedException":
              error = `This account has been disabled. Please contact ${
                tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
                config.defaultAdminEmail
              }`;
              break;
            default:
              error = `Error: Please try again later or contact ${
                tenantConfig?.cmsSettings?.websiteSettings?.adminEmail ??
                config.defaultAdminEmail
              }`;
              Sentry.captureException({
                message: "Forgot Password Failure. Default case.",
                data,
              });
              break;
          }
          setStatusMessages({ errorMessage: error, successMessage: null });
          break;
        case "forgotPasswordSubmit":
          setStatusMessages({
            errorMessage: null,
            successMessage:
              "Your password has been changed. Log in with your new password.",
          });
          break;
        case "forgotPasswordSubmit_failure":
          switch (data.code) {
            case "CodeMismatchException":
              error =
                "The code entered is not valid. Please check your email and re-enter.";
              break;
            case "LimitExceededException":
              error = `You've reached your maximum Forgot Password reset requests. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`;
              break;
            case "InvalidPasswordException":
            case "InvalidParameterException":
              error =
                "The new password must contain at least 8 characters, upper and lowercase letters, numerical value and special character.";
              break;
            case "ExpiredCodeException":
              error =
                "The code entered has expired. Please select 'Resend Code' below to receive a new one.";
              break;
            default:
              error = `Error: Please try again later or contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail}`;
              Sentry.captureException({
                message: "Forgot Password Submit Failure. Default case.",
                data,
              });
              break;
          }
          setStatusMessages({ errorMessage: error, successMessage: null });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          switch (data.code) {
            case "UserNotFoundException":
              error =
                "The email is not associated with an existing account. Please click below to create an account.";
              break;
            case "LimitExceededException":
              error =
                "You've reached maximum log in attempts. Please select 'Forgot Password' to reset your log in details.";
              break;
            case "NotAuthorizedException":
              error =
                data.message === "User is disabled."
                  ? // ? `This account has been disabled. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail}`
                    "Log in has been temporarily suspended for maintenance. Please check back after 6pm EDT"
                  : "Incorrect password. Please try again.";
              break;
            default:
              error = `Error: Please try again later or contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail}`;
              Sentry.captureException({
                message: "Sign in failure. Default case.",
                data,
              });
              break;
          }
          setStatusMessages({ errorMessage: error, successMessage: null });
          break;
        default:
          setStatusMessages({ errorMessage: null, successMessage: null });
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch, rememberMyDetails]);

  const setStatusMessages = ({
    errorMessage,
    successMessage,
  }: {
    errorMessage: string | null;
    successMessage: string | null;
  }) => {
    setPasswordResetSuccessMessage(successMessage);
    setErrorMessage(errorMessage);
  };

  const handleLoggedUser = () => {
    dispatch(getUserDetails() as any);
    props.onClose && props.onClose();
  };

  const handleForgotPassword = () => {
    setShowRememberMyDetails(false);
    const userEmailElement = document.querySelector(
      "[data-amplify-authenticator-signin] [name=username]",
    );
    if (userEmailElement) {
      const usernameValue = (userEmailElement as HTMLInputElement).value;
      if (usernameValue?.length > 0) {
        localStorage.setItem(REMEMBER_EMAIL_RESET, usernameValue);
      } else {
        localStorage.removeItem(REMEMBER_EMAIL_RESET);
      }
    }
  };

  const handleSignInPasswordFocus = (element: HTMLElement) => {
    setShowRememberMyDetails(true);
    element.blur();
  };

  const handleResetPasswordFocus = (element: HTMLElement) => {
    const forgotPasswordHtmlElement = document.querySelector(
      "[data-amplify-authenticator-resetpassword] [name=username]",
    );
    if (forgotPasswordHtmlElement) {
      (forgotPasswordHtmlElement as HTMLInputElement).value =
        localStorage.getItem(REMEMBER_EMAIL_RESET) ?? "";
    }
    element.blur();
  };

  const services = {
    async handleForgotPassword(email: string) {
      let res: any;
      try {
        res = await getVerifiedSignInEmail(email);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          setStatusMessages({
            errorMessage:
              "The email is not associated with an existing account. Please click below to create an account.",
            successMessage: null,
          });

          return new Promise((_, reject) => {
            reject({
              name: "",
              code: "",
              message: "The email is not associated with an existing account.",
              stack: "",
            });
          });
        }

        setStatusMessages({
          errorMessage: `There was an error processing your request. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`,
          successMessage: null,
        });

        return new Promise((_, reject) => {
          reject({
            name: "",
            code: "",
            message: `There was an error processing your request. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`,
            stack: "",
          });
        });
      }
      return Auth.forgotPassword(res.data, {
        BaseAPIURL: config.baseApiUrl,
        TenantId: getTenantId()!,
        TemplateType: "14", //14 is the enum for "Forgot Password User".
      });
    },
    async handleSignIn(formData: any) {
      let { username: email, password } = formData;
      let res: any;
      try {
        res = await getVerifiedSignInEmail(email);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          setStatusMessages({
            errorMessage:
              "The email is not associated with an existing account. Please click below to create an account.",
            successMessage: null,
          });

          return new Promise((_, reject) => {
            reject({
              name: "",
              code: "",
              message: "The email is not associated with an existing account.",
              stack: "",
            });
          });
        }

        setStatusMessages({
          errorMessage: `There was an error processing your request. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`,
          successMessage: null,
        });

        return new Promise((_, reject) => {
          reject({
            name: "",
            code: "",
            message: `There was an error processing your request. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`,
            stack: "",
          });
        });
      }
      return Auth.signIn({
        username: res.data,
        password,
      });
    },
  };

  return {
    rememberEmail,
    showRememberMyDetails,
    tenantConfig,
    errorMessage,
    passwordResetSuccessMessage,
    services,
    setShowRememberMyDetails,
    setRememberMyDetails,
    handleForgotPassword,
    handleSignInPasswordFocus,
    handleResetPasswordFocus,
    setErrorMessage,
    setPasswordResetSuccessMessage,
  };
};

export default LoginLogic;
