import { useTenantConfig } from "@App/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationItemProps } from "./NotificationItemProps";

const useNotificationItemLogic = ({
  notification,
  maxMessageLength = 164,
  feedClient,
}: NotificationItemProps) => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const appUrl = useTenantConfig()?.appUIBaseURL;

  const canExpand = notification.data?.message?.length > maxMessageLength;
  const hasLink = !!notification.data?.link;
  const isRelativeLink =
    hasLink &&
    appUrl &&
    notification.data!.link.toLowerCase().startsWith(appUrl.toLowerCase());

  const isUnread = !notification.read_at;
  const title = notification.data?.title;
  const message =
    canExpand && !isExpanded
      ? notification.data?.message?.substring(0, maxMessageLength) + "..."
      : notification.data?.message;

  // mark as seen if it hasnt been
  if (!notification.seen_at) {
    feedClient.markAsSeen(notification);
  }

  const handleDeleteAsync = () => {
    feedClient.markAsArchived(notification);
  };

  // check if clicking on expand or trash action buttons
  const isClickOnAction = (e: any) =>
    e.target?.classList?.contains("trash-icon") ||
    e.target?.nearestViewportElement?.classList?.contains("trash-icon") ||
    e.target?.classList?.contains("expand-btn") ||
    e.target?.nearestViewportElement?.classList?.contains("expand-btn");

  const handleOnClick = async (e: any) => {
    // skip if clicking on an action button
    if (isClickOnAction(e)) {
      return;
    }

    if (isUnread) {
      feedClient.markAsRead(notification);
    }

    if (hasLink) {
      if (isRelativeLink) {
        navigate(
          notification.data?.link
            .toLowerCase()
            .replace(appUrl.toLowerCase(), "/"), // set to local relative link
        );
      } else {
        const win = window.open(notification.data!.link, "_blank");
        if (win != null) {
          win.focus();
        }
      }
    }
  };

  return {
    notification,
    title,
    message,
    canExpand,
    hasLink,
    isRelativeLink,
    isUnread,
    isExpanded,
    setIsExpanded,
    isClickOnAction,
    handleOnClick,
    handleDeleteAsync,
  };
};

export default useNotificationItemLogic;
