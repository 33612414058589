import {
  SHOW_LOGIN_MODAL,
  SHOW_REGISTRATION_MODAL,
} from "@App/constants/appConstants";
import {
  QUERY_KEY_USER_VENUE_AVAILABILITY,
  QUERY_KEY_USER_VENUE_SPACES_RATES,
  QUERY_KEY_USER_VENUES_RATES,
  QUERY_KEY_VENUE_SPACE_AVAILABILITY,
} from "@App/constants/queryKeyConstants";
import {
  useAvailableDates,
  useAvailableRatesAndTimes,
  useAvailableSpaceDates,
  useAvailableSpaceRatesAndTimes,
} from "@App/hooks";
import { AmenityType, Space } from "@App/models/amenity";
import {
  AvailableRatesOption,
  EndTimeOption,
  StartTimeOption,
} from "@App/models/AvailableRates";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@Store/store";
import { useQueryClient } from "@tanstack/react-query";
import { customEventPublish } from "@Utils/utils";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRateDropdowns } from "../utils/useRateDropdowns";
import { buildPaymentData, findIsCTAButtonDisabled } from "../utils/utils";

export default function VenueBookingLogic(
  venue?: AmenityType | Space,
  parentId?: string,
) {
  const isSpace = !!parentId;
  const queryClient = useQueryClient();

  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(),
  );
  const [selectedRate, setSelectedRate] = useState<
    AvailableRatesOption | null | undefined
  >();
  const [selectedStartTime, setSelectedStartTime] = useState<
    StartTimeOption | null | undefined
  >();
  const [selectedEndTime, setSelectedEndTime] = useState<
    EndTimeOption | null | undefined
  >();

  const { availableRates, isLoadingRates } = isSpace
    ? useAvailableSpaceRatesAndTimes({
        parentId,
        spaceId: venue?.id,
        selectedDate,
      })
    : useAvailableRatesAndTimes({
        parentId: venue?.id,
        selectedDate,
      });

  const isUserLogged = useMemo(
    () => !!userData.userInfo?.firstName,
    [userData],
  );

  const userType = useMemo(() => userData.userInfo?.userType, [userData]);

  const isCTAButtonDisabled = findIsCTAButtonDisabled(
    isUserLogged,
    userType ?? 0,
    selectedRate,
    selectedStartTime,
    selectedEndTime,
  );

  const {
    calendarSelectedMonth,
    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    getRateOptions,
    getStartTimes,
    getEndTimes,
    resetDropdowns,
  } = useRateDropdowns({
    rates: availableRates?.rates,
    selectedRate,
    selectedStartTime,
    setSelectedDate,
    setSelectedRate,
    setSelectedStartTime,
    setSelectedEndTime,
  });

  const { availableDates } = isSpace
    ? useAvailableSpaceDates({
        parentId,
        spaceId: venue?.id,
        selectedMonth: calendarSelectedMonth,
      })
    : useAvailableDates({
        parentId: venue?.id,
        selectedMonth: calendarSelectedMonth,
      });

  // Reset dropdowns and data when the user changes
  useEffect(() => {
    resetDropdowns();
    queryList.forEach((query) => {
      queryClient.invalidateQueries([query]);
    });
  }, [storeUser]);

  const paymentData = useMemo(() => {
    return buildPaymentData({
      venueId: isSpace ? parentId : venue?.id ?? "",
      venue,
      bookingData: {
        rateId: selectedRate?.value,
        rateName: selectedRate?.rateName,
        selectedDate: selectedDate?.toISOString(),
        startTime: selectedStartTime?.value,
        endTime: selectedEndTime?.value,
        cost: selectedEndTime?.cost,
      },
    });
  }, [
    isSpace,
    venue,
    parentId,
    selectedDate,
    selectedRate,
    selectedStartTime,
    selectedEndTime,
  ]);

  const handleNotLoggedUserLogin = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const handleNotLoggedUserRegister = () => {
    customEventPublish(SHOW_REGISTRATION_MODAL, {});
  };

  const resetForm = () => {
    // Have to use null for the change to be detected by the dropdowns
    setSelectedRate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
  };

  return {
    selectedDate,
    setSelectedDate,

    selectedRate,
    selectedStartTime,
    selectedEndTime,

    availableDates,

    isUserLogged,
    isLoadingRates,
    isCTAButtonDisabled,

    paymentData,

    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,

    getRateOptions,
    getStartTimes,
    getEndTimes,

    handleNotLoggedUserLogin,
    handleNotLoggedUserRegister,
    resetForm,
  };
}

const queryList = [
  QUERY_KEY_USER_VENUE_AVAILABILITY,
  QUERY_KEY_VENUE_SPACE_AVAILABILITY,
  QUERY_KEY_USER_VENUES_RATES,
  QUERY_KEY_USER_VENUE_SPACES_RATES,
];
