import { AmenityType, Space } from "@App/models/amenity";
import {
  Button,
  CalendarSelect,
  DropdownCheckbox,
  ImageFilesCarousel,
} from "@Components/index";
import { PaymentsProps } from "@Components/payments/PaymentsPropTypes";
import VenueBookingLogic from "./VenuesBookingLogic";

export function VenueBooking({
  parentId,
  isSpace = true,
  venue,
  bookingCtaLabel,
  handleCTAButton,
}: {
  parentId?: string;
  isSpace: boolean;
  venue?: AmenityType | Space;
  bookingCtaLabel: string;
  handleCTAButton: (paymentData: PaymentsProps) => void;
}) {
  const {
    selectedDate,
    setSelectedDate,

    selectedRate,
    selectedStartTime,
    selectedEndTime,

    availableDates,

    paymentData,
    isCTAButtonDisabled,

    isUserLogged,
    isLoadingRates,

    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,

    getRateOptions,
    getStartTimes,
    getEndTimes,

    handleNotLoggedUserLogin,
    handleNotLoggedUserRegister,
    resetForm,
  } = VenueBookingLogic(venue, parentId);

  return (
    <div className={"venues-spaces-items-item"}>
      <div className={"venues-spaces-items-item-left"}>
        {isSpace && (
          // Someone had the bright idea to call it a title on a parent and a name on a child...
          // @ts-ignore
          <div className={"venues-spaces-items-item-title"}>{venue.name}</div>
        )}
        <div className="venues-spaces-items-item-image venues-spaces-items-item-image-min">
          <ImageFilesCarousel
            showIndicators={venue?.files && venue?.files.length > 1}
            customHeight="248px"
            files={venue?.files || []}
          />
        </div>
      </div>
      <div className={"venues-spaces-items-item-right"}>
        <div className={"venues-spaces-items-item-right-top"}>
          {/* TODO: Don't let users go back in time */}
          <div className="venues-spaces-items-item-right-top-calendar">
            <CalendarSelect
              availableDates={availableDates?.dates ?? []}
              selected={selectedDate}
              onMonthChange={(selectedMonthDate) => {
                setSelectedDate(undefined);
                resetForm();
                handleMonthChange(selectedMonthDate);
              }}
              onChange={(selectedDate) => {
                resetForm();
                setSelectedDate(selectedDate);
              }}
              isVenuesView
              dataCy="calendar-select-venue-space-details"
            />
          </div>
          <div
            className={"venues-spaces-items-item-right-top-selectors"}
            data-cy="venue-space-rate-dropdowns"
          >
            <DropdownCheckbox
              label="Select Rate"
              handleChange={handleRateChange}
              optionSelected={selectedRate}
              options={getRateOptions()}
              isLoading={isLoadingRates}
              isClearable={false}
              isMulti={false}
              blurOnSelect={true}
            />
            <DropdownCheckbox
              label="From:"
              handleChange={handleStartTimeChange}
              optionSelected={selectedStartTime}
              options={getStartTimes()}
              isLoading={isLoadingRates}
              isDisabled={!selectedRate}
              isClearable={false}
              isMulti={false}
              blurOnSelect={true}
            />
            <DropdownCheckbox
              label="To:"
              handleChange={handleEndTimeChange}
              optionSelected={selectedEndTime}
              options={getEndTimes()}
              isLoading={isLoadingRates}
              isDisabled={!selectedStartTime}
              isClearable={false}
              isMulti={false}
              blurOnSelect={true}
            />
          </div>
        </div>
        {!isUserLogged && (
          <div className={"venues-spaces-items-item-right-banner"}>
            A valid account is needed to make a reservation. Please{" "}
            <span
              className="venues-spaces-items-item-right-banner-link"
              onClick={handleNotLoggedUserLogin}
            >
              Log In
            </span>{" "}
            or{" "}
            <span
              className="venues-spaces-items-item-right-banner-link"
              onClick={handleNotLoggedUserRegister}
            >
              Sign Up
            </span>
            .
          </div>
        )}
        <div
          className={"venues-spaces-items-item-right-cta"}
          data-cy="venues-spaces-details-proceed-btn"
        >
          <Button
            onClick={() => handleCTAButton(paymentData)}
            text={bookingCtaLabel}
            isDisabled={isCTAButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
