import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_NOTIFICATION_PREFERENCES } from "@App/constants/queryKeyConstants";
import { getUserNotificationPreferences } from "@Api/notifications";

export type NotificationPreferences = {
  push?: boolean;
  pushMarketing?: boolean;
  inAppMarketing?: boolean;
  emailMarketing?: boolean;
};

export type NotificationPreferencesHook = {
  preferences?: NotificationPreferences;
  isError?: boolean;
  isLoading?: boolean;
};

export default function useNotificationPreferences(): NotificationPreferencesHook {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY_NOTIFICATION_PREFERENCES],
    queryFn: getUserNotificationPreferences,
    staleTime: 60 * 60 * 1000,
  });
  return {
    preferences: data?.data,
    isLoading,
    isError,
  };
}
