import { getCommunityAssetAvailableDates } from "@App/api/amenities";
import {
  QUERY_KEY_USER_VENUE_AVAILABILITY,
  QUERY_KEY_VENUE_SPACE_AVAILABILITY,
} from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";
import { addWeeks, endOfMonth, formatISO, startOfMonth } from "date-fns";

export function useAvailableDates({
  parentId,
  selectedMonth,
}: {
  parentId?: string;
  selectedMonth: Date;
}) {
  if (!parentId) {
    return { availableDates: undefined, isLoadingDates: false };
  }

  const { startDate, endDate } = getStartAndEndDates(selectedMonth);

  const { data: availableDates, isLoading: isLoadingDates } = useQuery({
    queryKey: [QUERY_KEY_USER_VENUE_AVAILABILITY, parentId, selectedMonth],
    queryFn: () =>
      getCommunityAssetAvailableDates(parentId, startDate, endDate),
    enabled: !!parentId,
    select: (data) => data.data,
  });

  return { availableDates, isLoadingDates };
}

export function useAvailableSpaceDates({
  parentId,
  spaceId,
  selectedMonth,
}: {
  parentId: string;
  spaceId?: string;
  selectedMonth: Date;
}) {
  const { startDate, endDate } = getStartAndEndDates(selectedMonth);

  const { data: availableDates, isLoading: isLoadingDates } = useQuery({
    queryKey: [
      QUERY_KEY_VENUE_SPACE_AVAILABILITY,
      parentId,
      spaceId,
      selectedMonth,
    ],
    queryFn: () =>
      getCommunityAssetAvailableDates(
        parentId,
        startDate,
        endDate,
        `Space/${spaceId}`,
      ),
    enabled: !!parentId && !!spaceId,
    select: (data) => data.data,
  });

  return { availableDates, isLoadingDates };
}

function getStartAndEndDates(month: Date) {
  return {
    startDate: formatISO(addWeeks(startOfMonth(month), -1), {
      format: "extended",
      representation: "date",
    }),
    endDate: formatISO(addWeeks(endOfMonth(month), 1), {
      format: "extended",
      representation: "date",
    }),
  };
}
