import {
  Button,
  ButtonToggle,
  LabelWithValue,
  Loader,
  PasswordReset,
  Switch,
} from "@Components/index";
import EditProfile from "./edit-profile/EditProfile";
import { MyAccountProps } from "./MyAccountPropTypes";
import MyAccountLogic from "./MyAccountLogic";
import "./MyAccount.scss";
import { formatPhoneNumber } from "@Utils/utils";
import { useTranslation } from "react-i18next";

const MyAccount = (props: MyAccountProps) => {
  const { t } = useTranslation();
  const {
    handleReset,
    handleSave,
    handleSelectItem,
    parsedBirthDay,
    fullAddress,
    isEditProfileOpen,
    setIsEditProfileOpen,
    accountInfo,
    interestsUpdate,
    permissionsUpdate,
    setPermissionsUpdate,
    isChangePasswordOpen,
    setIsChangePasswordOpen,
    enableSaveButton,
    setEnableSaveButton,
    userPreferences,
    setUserPreferences,
    isSaving,
  } = MyAccountLogic(props);

  if (!accountInfo || props.isLoading) return <Loader color={"#000"} />;
  return (
    <>
      <div id="tab-panel">
        <div className="card">
          <div className="card-header">
            <img
              className={"card-image"}
              src={require("@Assets/images/avatar.png")}
              alt={""}
            />
            <LabelWithValue
              useBold
              label="Email"
              value={props.accountInfo?.email ?? "-"}
            />
            <LabelWithValue
              useBold
              label="Resident Address"
              value={fullAddress ?? "-"}
            />
            <LabelWithValue
              useBold
              label="Phone"
              value={
                (props.accountInfo &&
                  formatPhoneNumber(props.accountInfo?.phone)) ??
                "-"
              }
            />
            <LabelWithValue
              useBold
              label="Type of member"
              value={t(props.accountInfo?.userType ?? "")}
            />
            <LabelWithValue label="Birthday" useBold value={parsedBirthDay} />
            <button
              className="card-header__editButton"
              onClick={() => setIsEditProfileOpen(!isEditProfileOpen)}
            >
              {"(Edit Profile)"}
            </button>
          </div>
          <div className="card-content">
            <div>
              <div className="card-title">Your interests</div>
              <div className="interest-list">
                {props.accountInfo?.interests.map(
                  ({ name, id, isFavorite }) => (
                    <ButtonToggle
                      text={name}
                      key={id}
                      isSelected={interestsUpdate
                        .map((selectedId) => selectedId)
                        .includes(id)}
                      onClick={() => handleSelectItem(id)}
                    />
                  ),
                )}
              </div>
            </div>

            <div>
              <div className="card-title">Permissions</div>
              <div className="permission-list">
                <div className="permission-item">
                  <p className="permission-name">Location Services</p>
                  <Switch
                    checked={permissionsUpdate.isLocationServiceEnabled}
                    onChange={(val: boolean) =>
                      setPermissionsUpdate((prev) => {
                        setEnableSaveButton(true);
                        return {
                          ...prev,
                          isLocationServiceEnabled: !!val,
                        };
                      })
                    }
                  />
                </div>
                <div className="permission-item">
                  <p className="permission-name">Notifications</p>
                  <Switch
                    checked={userPreferences?.push ?? false}
                    onChange={(val: boolean) =>
                      setUserPreferences((prev) => {
                        setEnableSaveButton(true);
                        return {
                          ...prev,
                          push: val,
                          // if push is now enabled and marketing is enabled for any channel, enable push marketing
                          pushMarketing: val && prev?.inAppMarketing,
                        };
                      })
                    }
                  />
                </div>
                <div className="permission-item">
                  <p className="permission-name">Marketing Communications</p>
                  <Switch
                    checked={
                      (userPreferences?.emailMarketing ||
                        userPreferences?.inAppMarketing ||
                        userPreferences?.pushMarketing) ??
                      false
                    }
                    onChange={(val: boolean) =>
                      setUserPreferences((prev) => {
                        setEnableSaveButton(true);
                        return {
                          ...prev,
                          inAppMarketing: val,
                          pushMarketing: val,
                          emailMarketing: val,
                        };
                      })
                    }
                  />
                </div>
                <div
                  className={`card-actions ${enableSaveButton ? "" : "hidden"}`}
                >
                  <Button
                    style={{ width: "fit-content", height: "56px" }}
                    text="Reset"
                    onClick={handleReset}
                    isSecondary
                  />
                  <Button
                    style={{ width: "fit-content", height: "56px" }}
                    text="Save"
                    isDisabled={isSaving}
                    onClick={handleSave}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="card-title">Password</div>
              <Button
                style={{ width: "171px", height: "49px" }}
                text="Change Password"
                onClick={() => setIsChangePasswordOpen(true)}
                isSecondary
              />
            </div>
          </div>
          <div className="card-footer"></div>
        </div>
      </div>
      <EditProfile
        isVisible={isEditProfileOpen}
        onClose={() => {
          setIsEditProfileOpen(false);
        }}
        accountInfo={accountInfo}
      />
      <PasswordReset
        isVisible={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
        userData={accountInfo}
      />
    </>
  );
};

export default MyAccount;
