import { initiateMembershipPayment } from "@App/api/memberships";
import { MEMBERSHIP_DOGS } from "@App/constants/appConstants";
import { QUERY_KEY_USER_RESERVATION_LIST } from "@App/constants/queryKeyConstants";
import useTenantConfig from "@App/hooks/api/tenant/useTenantConfig";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MembershipPaymentLogic = () => {
  const [membershipTitle, setMembershipTitle] = useState(String);
  const [parkName, setParkName] = useState(String);
  const [files, setFiles] = useState<any[]>([]);
  const { userMembershipId, rateId } = useParams<{
    userMembershipId: string;
    rateId: string;
  }>();
  const [membershipPurchaseData, setMembershipPurchaseData] =
    useState<any>(null);
  const queryClient = useQueryClient();
  const tenantConfig = useTenantConfig();

  const [isLoadingPurchaseData, setIsLoadingPurchaseData] = useState(false);

  useEffect(() => {
    const initiatePayment = async () => {
      if (userMembershipId && rateId) {
        try {
          setIsLoadingPurchaseData(true);
          const res = await initiateMembershipPayment({
            membershipRequests: [{ userMembershipId, rateId }],
          });
          setMembershipPurchaseData(res);
        } catch (error) {
          alert(
            `There was an error processing your request. Please contact ${tenantConfig?.cmsSettings?.websiteSettings?.adminEmail} for assistance.`,
          );
          console.log(error);
        } finally {
          setIsLoadingPurchaseData(false);
        }
      }
    };

    initiatePayment();
  }, [rateId, userMembershipId]);

  useEffect(() => {
    //This can be dogs or anything else. We need to add the other scenarios we need.
    const membershipDetail = localStorage.getItem(MEMBERSHIP_DOGS);
    if (membershipDetail) {
      const membershipData = JSON.parse(membershipDetail);
      setMembershipTitle(membershipData.title);
      setParkName(membershipData.preferredPark);

      setFiles(membershipData.files);
    }
  }, []);

  const onPaymentSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_USER_RESERVATION_LIST]);
  };

  return {
    membershipTitle,
    parkName,
    membershipPurchaseData,
    files,
    onPaymentSuccess,
    isLoadingPurchaseData,
  };
};

export default MembershipPaymentLogic;
