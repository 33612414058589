import { GenericPageItem } from "@App/models/BasePagedResponse";
import { flexRender, Row, Table } from "@tanstack/react-table";
import { memo } from "react";
import "./Table.scss";

function TableBody<TData>({
  table,
  resizeSpeed,
  highlightOnHover,
  onClick,
  onClickLinkTo,
}: {
  table: Table<GenericPageItem<TData>>;
  resizeSpeed: number;
  highlightOnHover?: boolean;
  onClick?: (row: Row<GenericPageItem<TData>>) => void;
  onClickLinkTo?: (row: Row<GenericPageItem<TData>>) => string;
}) {
  const highlightClass = highlightOnHover ? `table-row-highlight` : "";

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => {
        const empty = row.original?.empty;
        return (
          <tr
            key={row.id}
            className={`table-row ${highlightClass} ${
              empty ? "" : `${!!onClick && !empty ? "cursor-pointer" : ""}`
            }`}
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <a
                  href={
                    !cell.column.columnDef.meta?.disableLinking && onClickLinkTo
                      ? onClickLinkTo(row)
                      : undefined
                  }
                  onClick={() =>
                    !cell.column.columnDef.meta?.disableLinking &&
                    !!onClick &&
                    !empty &&
                    onClick(row)
                  }
                  className="table-row-link"
                  style={{
                    width: `var(--col-${cell.column.id}-size)`,
                  }}
                >
                  <td
                    key={cell.id}
                    className={`table-row-link-td
                    ${
                      cell.column.columnDef.enableSorting ? "px-6" : "" // align start of cell with header
                    } 
                    ${
                      cell.column.columnDef.meta?.centerAligned
                        ? "table-row-link-td-center"
                        : "table-row-link-td-left"
                    }
                    `}
                    style={{
                      width: `var(--col-${cell.column.id}-size)`,
                    }}
                  >
                    <p>
                      {empty
                        ? null
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                    </p>
                  </td>
                </a>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

// Memoized during resizing to prevent expensive re-renders
const MemoizedTableBody = memo(
  TableBody,
  (prevProps, nextProps) =>
    prevProps.table.options.data === nextProps.table.options.data,
) as typeof TableBody;

export { MemoizedTableBody, TableBody };
