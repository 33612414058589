import "./SupportRequestModal.scss";
import { Button, Modal } from "@Components/index";
import TextArea from "@Components/text-area/TextArea";
import { useState } from "react";

export interface SupportRequestModalProps {
  title: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  children?: any;
  onRequest: (request: string, clearTextFunc: () => void) => void;
  onCancel: () => void;
}

const SupportRequestModal = ({
  title,
  isVisible,
  onRequest,
  isDisabled,
  children,
  onCancel,
}: SupportRequestModalProps) => {
  const [requestText, setRequestText] = useState("");

  return (
    <Modal isVisible={!!isVisible} onClose={onCancel}>
      <div className={"support-request-modal"} data-cy="prompts-modal">
        <div className="prompts-modal__title">{title}</div>

        {children}

        <TextArea
          height={"150px"}
          value={requestText}
          label={"Please provide the specific details of your request below:"}
          onChange={(text) => setRequestText(text)}
        />

        <Button
          text={"Send Request"}
          isDisabled={isDisabled ?? false}
          onClick={() => onRequest(requestText, () => setRequestText(""))}
        />
        <Button
          text={"Cancel"}
          isSecondary={true}
          isDisabled={isDisabled ?? false}
          onClick={onCancel}
        />
      </div>
    </Modal>
  );
};

export default SupportRequestModal;
