import { Space } from "@App/models/amenity";
import { SideScroll } from "@Components/index";
import { PaymentsProps } from "@Components/payments/PaymentsPropTypes";
import { useState } from "react";
import { VenueBooking } from "../venue-booking/VenuesBooking";
import { VenuesSpaceCard } from "./VenuesSpaceCard";
import "./VenuesSpaces.scss";

interface VenuesSpacesProps {
  parentId?: string;
  spaces?: Space[];
  bookingCtaLabel: string;
  handleCTAButton: (paymentData: PaymentsProps) => void;
  onShowBookingOptions: () => void;
  isChildFormOpen: boolean;
  setIsChildFormOpen: (value: boolean) => void;
}

export default function VenuesSpaces({
  parentId,
  spaces,
  bookingCtaLabel,
  handleCTAButton,
  onShowBookingOptions,
  isChildFormOpen,
  setIsChildFormOpen,
}: VenuesSpacesProps) {
  const [selectedSpace, setSelectedSpace] = useState<Space>();

  return (
    <div className={"venues-spaces"}>
      {!!selectedSpace && isChildFormOpen && (
        <div className="venues-spaces-items venues-spaces-selected">
          <VenueBooking
            key="space_detail"
            isSpace={true}
            parentId={parentId}
            venue={selectedSpace}
            bookingCtaLabel={bookingCtaLabel}
            handleCTAButton={handleCTAButton}
          />
        </div>
      )}
      <div
        className={`${!!selectedSpace && isChildFormOpen ? "venues-spaces-bottom" : ""}`}
      >
        <SideScroll verticalMargin={50}>
          <div className={"venues-spaces-bottom-items"}>
            {spaces?.map((space, index) => {
              return space.id !== selectedSpace?.id ? (
                <VenuesSpaceCard
                  key={`space_item_bottom${index}`}
                  space={space}
                  onClick={() => {
                    onShowBookingOptions();
                    setSelectedSpace(space);
                    setIsChildFormOpen(true);
                  }}
                />
              ) : null;
            })}
          </div>
        </SideScroll>
      </div>
    </div>
  );
}
