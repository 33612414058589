import {
  Membership,
  MembershipsData,
  UserMembershipKeyFob,
} from "@App/models/membership";
import { baseServiceRequest } from "./baseService";
import {
  AddMembershipWaiverParams,
  CreateUserMembershipRequest,
  GetMembershipsRequest,
} from "./requests/memberships";
import {
  UserMembershipFileRequest,
  UserMembershipsPagedRequest,
  UserMembershipsPagedResponse,
} from "./requests/userMemberships";

export const getMemberships = async (params: GetMembershipsRequest) => {
  return await baseServiceRequest<MembershipsData>({
    path: `/membership/Search`,
    type: "get",
    params,
  });
};

export const getMembershipDetailsById = async (id: string) => {
  return await baseServiceRequest<Membership>({
    path: `/membership/${id}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getUserMembershipKeyFobDetailsById = async (id: string) => {
  return await baseServiceRequest<UserMembershipKeyFob>({
    path: `/usermembership/${id}/keyfob`,
    type: "get",
    useAuthHeader: true,
  });
};

export const createUserMembership = async (
  data: CreateUserMembershipRequest,
) => {
  return await baseServiceRequest<string>({
    path: `/usermembership`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const addUserMembershipFile = async (
  userMembershipId: string,
  data: UserMembershipFileRequest,
) => {
  return await baseServiceRequest({
    path: `/usermembership/${userMembershipId}/file`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const addManyUserMembershipFiles = async (
  userMembershipId: string,
  data: UserMembershipFileRequest[],
) => {
  return await baseServiceRequest({
    path: `/usermembership/${userMembershipId}/files`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const addWaiverUserMembership = async (
  data: AddMembershipWaiverParams,
) => {
  return await baseServiceRequest({
    path: `/usermembership/${data.id}/waiver`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const initiateMembershipPayment = async (data: any) => {
  return await baseServiceRequest({
    path: `/stripe/InitiatePaymentRequest`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

export const getUserMemberships = async (
  params: UserMembershipsPagedRequest,
) => {
  const unwoundParams = {
    ...params.body.pageParams,
    ...params.body.sortOptions,
    ...params.body.filterOptions,
  };
  return await baseServiceRequest<UserMembershipsPagedResponse>({
    path: `/usermembership/user`,
    type: "get",
    useAuthHeader: true,
    params: unwoundParams,
  });
};
