import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationState, UserState } from "@Store/reducers/userReducer";
import { RootState } from "@Store/store";
import {
  createUserAccount,
  logout,
  validateUserFirstLogin,
} from "@Store/actions/userActions";
import { REGISTRATION_EXISTING_ACCOUNT } from "@App/api/statusCodes";
import {
  PREVENT_REGISTRATION_DETAILS,
  REGISTRATION_CLEAR_ERROR,
  SHOW_REGISTRATION_DETAILS,
  USER_REGISTRATION_HIDE_BANNER,
} from "@App/constants/userConstants";
import {
  customEventSubscribe,
  customEventUnsubscribe,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import {
  RSVP_LOGIN_FLOW,
  SHOW_LOGIN_MODAL,
  SHOW_REGISTRATION_MODAL,
  SHOW_RESULT_BANNER,
} from "@App/constants/appConstants";
import userSession from "@App/auth/userSession";
import { useAuthUser, useTenantConfig } from "@App/hooks";

const NavbarLogic = () => {
  const currentRoute = useLocation().pathname;
  const formData = localStorage.getItem("cp-resident-registration-form-data");
  let formDataObject = formData ? JSON.parse(formData) : null;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState(
    "Loading, please wait",
  );
  const [showResultBanner, setShowResultBanner] = useState(false);
  const [resultBannerMessage, setResultBannerMessage] = useState("");
  const [showRegistrationUserDetails, setShowRegistrationUserDetails] =
    useState(false);
  const [isDuplicateAccountError, setIsDuplicateAccountError] = useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isResidentialStatusProvided, setIsResidentialStatusProvided] =
    useState(true);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const [isloadingUserData, setIsloadingUserData] = useState(false);
  const [userDataParameters, setUserDataParameters] = useState<any>(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );
  const [navigationMenuVisible, setNavigationMenuVisible] = useState(false);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  const storeData = useSelector<
    RootState,
    {
      userData: UserState;
      registrationData: RegistrationState;
    }
  >((state) => {
    return {
      userData: state.userProfile,
      registrationData: state.userRegistration,
    };
  });

  const { userData, registrationData } = storeData;
  const { isUserLoggedIn } = useAuthUser();
  const tenantConfig = useTenantConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleCustomEventLogin = (data: any) => {
      const clearRsvpFlow = data.detail?.cleanRsvpFlow ?? true;
      if (clearRsvpFlow) localStorage.removeItem(RSVP_LOGIN_FLOW);

      // Tech: if mobile embed, show the mobile native app login. Otherwise, show the web modal.
      if (isMobileEmbed) {
        sendMobileViewModePostMessage({ showLogin: true });
      } else {
        setShowLogin(true);
      }
    };
    const handleCustomEventRegistration = () => {
      // Add mobile option?
      setShowRegistration(true);
    };

    if (!isloadingUserData) {
      const url = new URL(window.location.href);
      const searchParams = url.searchParams;
      const code = searchParams.get("C");
      const emailEncoded = searchParams.get("E");
      if (code && emailEncoded) {
        window.history.replaceState(null, "", window.location.pathname);
        const email = decodeURI(emailEncoded);
        setIsloadingUserData(true);
        validateUserFirstLogin({ email, userVerificationCode: code }).then(
          (response) => {
            setIsloadingUserData(false);
            if (response.email) {
              setUserDataParameters({
                id: response.id,
                code,
                email: response.email,
                firstname: response.firstName,
                lastname: response.lastName,
                userType: response.userType,
              });
              setShowRegistration(true);
            } else {
              setLoginErrorMessage(
                "You previously already validated your account with a password. Please log in below to continue or select 'Forgot Password'.",
              );
              setShowLogin(true);
            }
          },
        );
      }
    }

    customEventSubscribe(SHOW_LOGIN_MODAL, handleCustomEventLogin);
    customEventSubscribe(SHOW_RESULT_BANNER, showCustomResultBanner);
    customEventSubscribe(
      SHOW_REGISTRATION_MODAL,
      handleCustomEventRegistration,
    );
    return () => {
      customEventUnsubscribe(SHOW_LOGIN_MODAL, handleCustomEventLogin);
      customEventUnsubscribe(SHOW_RESULT_BANNER, showCustomResultBanner);
      customEventUnsubscribe(
        SHOW_REGISTRATION_MODAL,
        handleCustomEventRegistration,
      );
    };
  }, [isloadingUserData, isMobileEmbed]);

  useEffect(() => {
    if (userData?.userInfo) {
      setIsEmailVerified(userData.userInfo.accountVerification.isEmailVerified);
      setIsResidentialStatusProvided(
        userData.userInfo.accountVerification.isResidentialStatusProvided,
      );
    }

    const isProcessingRegistration =
      registrationData?.isProcessing ||
      (showLogin && !userData.userInfo?.firstName);
    setIsProcessing(isProcessingRegistration);

    if (isProcessingRegistration) {
      setShowRegistration(false);
    } else if (registrationData.registrationError) {
      handleRegistrationError();
    } else {
      setIsDuplicateAccountError(false);
    }

    const displayAccountDetailsForm =
      !registrationData.preventRegistrationDetails &&
      userData?.userInfo &&
      !userData.loading &&
      userData.userInfo.accountVerification.isEmailVerified &&
      !userData.userInfo.accountVerification.isResidentialStatusProvided;

    handleRegistrationDetailsFormVisibility(
      (displayAccountDetailsForm ||
        registrationData?.showRegistrationDetails) ??
        false,
    );
    handleRegistrationResultsBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationData, userData]);

  const handleRegistrationResultsBanner = () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const isVerificationBanner = searchParams.get("R") === "verification";
    const showResultBanner =
      (registrationData.showRegistrationDetailsResultBanner ||
        registrationData.isReSendVerificationSuccess ||
        isVerificationBanner) ??
      false;

    setResultBannerMessage(
      registrationData.isReSendVerificationSuccess
        ? "Email has been sent"
        : isVerificationBanner
          ? "Your account has been verified"
          : "Success! Your account has been registered!",
    );
    setShowResultBanner(showResultBanner);
  };

  const showCustomResultBanner = (data: any) => {
    setResultBannerMessage(data.detail);
    setShowResultBanner(true);
  };

  const handleRegistrationError = () => {
    setShowRegistration(true);
    switch (registrationData.registrationError) {
      case REGISTRATION_EXISTING_ACCOUNT:
        setIsDuplicateAccountError(true);
        break;
      default:
        break;
    }
  };

  const handleRegistrationDetailsFormVisibility = (
    showRegistrationDetailsForm: boolean,
  ) => {
    if (showRegistration) {
      setProcessingMessage("Creating account, please wait");
    } else if (showRegistrationDetailsForm) {
      setProcessingMessage("Updating account, please wait");
    } else if (showLogin) {
      setProcessingMessage("Logging in, please wait");
    }
    setShowRegistrationUserDetails(showRegistrationDetailsForm);
  };

  const createAccount = (idToken?: string) => {
    dispatch(
      createUserAccount({
        firstname: formDataObject.firstname,
        lastname: formDataObject.lastname,
        email: formDataObject.email,
        ssoProvider: formDataObject ? formDataObject.ssoProvider : null,
        idToken,
      }) as any,
    );
  };

  if (
    localStorage.getItem("cp-resident-registration-form-data") &&
    window.location.href.indexOf("#id_token=") > -1
  ) {
    const idTokenValue = window.location.hash
      .split("&")[0]
      .replace("#id_token=", "");
    createAccount(idTokenValue);
  }
  if (formDataObject) {
    localStorage.removeItem("cp-resident-registration-form-data");
  }

  const handleRegistrationModalClose = (showLogin?: boolean) => {
    setUserDataParameters(null);
    dispatch({
      type: REGISTRATION_CLEAR_ERROR,
      payload: {},
    });
    setShowRegistration(false);
    if (showLogin) {
      //Needs to close the Registration Modal first (prevent 2 modals at the same time)
      setTimeout(() => {
        setShowLogin(true);
      }, 100);
    }
  };

  const handleLogout = () => {
    setNavigationMenuVisible(false);
    setIsUserMenuVisible(false);
    dispatch(logout() as any);
  };

  const hideResultBanner = () => {
    window.history.replaceState(null, "", window.location.pathname);
    dispatch({
      type: USER_REGISTRATION_HIDE_BANNER,
    });
    setShowResultBanner(false);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  const onRegistrationDetailsSave = () => {
    dispatch({
      type: PREVENT_REGISTRATION_DETAILS,
    });

    //(Manuel) - Wait 2 seconds to display the user the status of the process (instead of closing the modal after save, it does the save very fast).
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
    }, 2000);
  };

  const onRegistrationDetailsClose = () => {
    dispatch({
      type: PREVENT_REGISTRATION_DETAILS,
    });
  };

  return {
    isMenuVisible,
    currentRoute,
    userInfo: userData.userInfo,
    isUserMenuVisible,
    showRegistration,
    showLogin,
    isProcessing,
    processingMessage,
    showRegistrationUserDetails,
    showResultBanner,
    resultBannerMessage,
    isDuplicateAccountError,
    isEmailVerified,
    isResidentialStatusProvided,
    showResendEmailModal,
    isloadingUserData,
    userDataParameters,
    loginErrorMessage,
    navigationMenuVisible,
    tenantConfig,
    isMobileEmbed,
    isLogged: isUserLoggedIn,
    areCommunityUserAccountsDisabled:
      tenantConfig?.featureToggles?.areCommunityUserAccountsDisabled,
    thirdPartyNavbar: tenantConfig?.features?.thirdPartyNavbar,
    nonLoggedInUserWelcome:
      tenantConfig?.features?.nonLoggedInUserWelcome || "",
    setIsProcessing,
    setProcessingMessage,
    setShowRegistration,
    setShowLogin,
    setShowRegistrationUserDetails,
    setIsMenuVisible,
    setIsUserMenuVisible,
    handleLogout,
    handleRegistrationModalClose,
    hideResultBanner,
    showAccountDetails,
    setShowResendEmailModal,
    setNavigationMenuVisible,
    onRegistrationDetailsSave,
    onRegistrationDetailsClose,
  };
};

export default NavbarLogic;
