import { UpdateNotificationPreferencesRequest } from "@Api/requests/notifications";
import { KnockUserToken } from "@App/models/notification";
import { baseServiceRequest } from "./baseService";

export const getUserKnockToken = async () => {
  return await baseServiceRequest<KnockUserToken>({
    path: `/notification/knock-token`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getUserNotificationPreferences = async () => {
  return await baseServiceRequest({
    path: `/notification/preferences`,
    type: "get",
    useAuthHeader: true,
  });
};

export const updateNotificationPreferences = async (
  params: UpdateNotificationPreferencesRequest,
) => {
  return await baseServiceRequest({
    path: `/notification/preferences`,
    type: "put",
    postData: params,
    useAuthHeader: true,
  });
};
