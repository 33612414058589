import { useAuthUser, useNavbarItems } from "@App/hooks";
import { QUERY_KEY_NAV_BAR_ITEMS } from "@App/constants/queryKeyConstants";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

const NavbarItemsLogic = () => {
  const queryClient = useQueryClient();
  const { navbarItems, isLoadingNavbarItems } = useNavbarItems();
  const { isUserLoggedIn } = useAuthUser();

  useEffect(() => {
    // refetch navbar items when user status changes
    if (isUserLoggedIn) {
      queryClient.invalidateQueries([QUERY_KEY_NAV_BAR_ITEMS]);
    }
  }, [isUserLoggedIn]);

  return {
    navbarItems,
    isLoadingNavbarItems,
  };
};

export default NavbarItemsLogic;
