import {
  BackButton,
  Breadcrumb,
  Button,
  GenericModal,
  ImageFilesCarousel,
  Loader,
  Payments,
} from "@Components/index";
import { Navbar } from "@Layouts/index";
import { useState } from "react";
import "./VenuesDetail.scss";
import VenuesDetailLogic from "./VenuesDetailLogic";
import { VenueBooking } from "./venue-booking/VenuesBooking";
import VenuesSpaces from "./venues-spaces/VenuesSpaces";

const VenuesDetail = () => {
  const {
    communityAsset,
    isLoadingCommunityAsset,
    paymentData,

    bookingCtaLabel,
    handleCTAButton,

    isMobileEmbed,
    hasSpaceBooking,

    showVenueBookingPayment,
    setShowVenueBookingPayment,
    showReservationRules,
    setShowReservationRules,
    showVenueBooking,
    setShowVenueBooking,

    goBack,
    onPaymentSuccess,
  } = VenuesDetailLogic();

  const [isChildFormOpen, setIsChildFormOpen] = useState(false);

  return (
    <Navbar>
      {isLoadingCommunityAsset ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : showVenueBookingPayment ? (
        <div
          className={"venue-detail-booking-payment"}
          data-cy="venue-booking-payment-view"
        >
          <Payments
            {...paymentData!}
            goBack={() => setShowVenueBookingPayment(false)}
            onPaymentSuccess={onPaymentSuccess}
          />
        </div>
      ) : (
        <div className={"venues-detail-container"}>
          <div className={"venue-detail-breadcrumb"}>
            <Breadcrumb
              initialSection={{ text: "Home", path: "/" }}
              currentSectionName={communityAsset?.title}
              hasIdentificator
            />
          </div>
          {!isMobileEmbed && (
            <div className={"venue-detail-back"}>
              <BackButton goBack={goBack} />
            </div>
          )}
          <div className="venue-detail-card-wrapper">
            <div className={"venue-detail-card"}>
              <div className={"venue-detail-card-content-images"}>
                <ImageFilesCarousel
                  showIndicators={
                    communityAsset?.files && communityAsset?.files.length > 1
                  }
                  customHeight="155px"
                  files={communityAsset?.files || []}
                />
              </div>
              <div className={"venue-detail-card-content"}>
                <div>
                  <div className={"venue-detail-card-content-title"}>
                    {communityAsset?.title}
                  </div>
                  <div className={"venue-detail-card-content-body"}>
                    <div
                      className={"venue-detail-card-content-description"}
                      dangerouslySetInnerHTML={{
                        __html: communityAsset?.description ?? "",
                      }}
                    />
                  </div>
                </div>
              </div>
              {communityAsset?.canBeBooked && (
                <div>
                  <Button
                    text={"Check Dates and Rates"}
                    onClick={() => {
                      setIsChildFormOpen(false);
                      setShowVenueBooking(true);
                    }}
                    isSecondary
                    dataCy="venue-details-parent-check-dates-btn"
                  />
                </div>
              )}
            </div>
          </div>

          {showVenueBooking && (
            <div className="venues-spaces">
              <div className="venues-spaces-items">
                <VenueBooking
                  isSpace={false}
                  venue={communityAsset}
                  bookingCtaLabel={bookingCtaLabel}
                  handleCTAButton={handleCTAButton}
                />
              </div>
            </div>
          )}
          {hasSpaceBooking && (
            <VenuesSpaces
              parentId={communityAsset?.id}
              spaces={communityAsset?.spaces?.filter(
                (space) => space.canBeBooked,
              )}
              bookingCtaLabel={bookingCtaLabel}
              handleCTAButton={handleCTAButton}
              onShowBookingOptions={() => {
                setShowVenueBooking(false);
              }}
              isChildFormOpen={isChildFormOpen}
              setIsChildFormOpen={setIsChildFormOpen}
            />
          )}
        </div>
      )}
      {communityAsset?.financeRules?.rulesandRegulation && (
        <GenericModal
          onClose={() => setShowReservationRules(false)}
          isVisible={showReservationRules}
          ctaText={"Close"}
          onCtaClick={() => setShowReservationRules(false)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: communityAsset?.financeRules?.rulesandRegulation,
            }}
          ></div>
        </GenericModal>
      )}
    </Navbar>
  );
};

export default VenuesDetail;
