import { AxiosGenericPagedResponse } from "@App/models/BasePagedResponse";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import {
  buildQueryFnParams,
  unwrapPaginationRequest,
} from "@Components/table/utils";
import {
  TableSortOptions,
  UsePagedTableQueryParams,
} from "@Components/table/types";

// sorts on API are represented by `-<field>` when desc sorting and `<field>` when asc sorting
export const sortObjectToStr = (sortOptions: TableSortOptions<any>) =>
  Object.entries(sortOptions)
    .map((e) => `${e[1] ? "-" : ""}${e[0]}`)
    .join(",");

export default function useTablePagedQuery<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
>({
  queryKey,
  queryFn,
  id,
  pagination,
  sortOptions,
  filterOptions,
  enabled = true,
}: UsePagedTableQueryParams<TData, TSortOptions, TFilterOptions>) {
  const queryClient = useQueryClient();
  const page = useMemo(() => pagination.pageIndex + 1, [pagination.pageIndex]);

  const qkSortObj =
    sortOptions && Object.keys(sortOptions).length > 0 // only add sorts to query param if any are present
      ? { sort: sortObjectToStr(sortOptions)! }
      : {};

  const { data, error, isLoading, isFetching, isPreviousData } = useQuery<
    AxiosGenericPagedResponse<TData>
  >({
    queryKey: [
      ...queryKey,
      ...Object.values(id ? { id: id } : {}),
      ...Object.values(filterOptions ?? {}),
      ...Object.values(qkSortObj),
      page,
      pagination.pageSize,
    ],
    queryFn: () =>
      queryFn(
        buildQueryFnParams({
          id,
          sortOptions,
          filterOptions,
          pageIndex: page,
          pageSize: pagination.pageSize,
        }),
      ),
    keepPreviousData: true,
    enabled,
    staleTime: 1000 * 60 * 5,
  });

  const unwrappedData = useMemo(
    () =>
      unwrapPaginationRequest<TData>(
        data?.data,
        page,
        pagination.pageSize,
        true,
      ),
    [data],
  );

  useEffect(() => {
    if (!enabled || !data || data.data?.isLastPage) return;

    queryClient.prefetchQuery({
      queryKey: [
        ...queryKey,
        ...Object.values(id ? { id: id } : {}),
        ...Object.values(filterOptions ?? {}),
        ...Object.values(qkSortObj),
        page + 1,
        pagination.pageSize,
      ],
      queryFn: () =>
        queryFn(
          buildQueryFnParams({
            id,
            sortOptions,
            filterOptions,
            pageIndex: page + 1,
            pageSize: pagination.pageSize,
          }),
        ),
      staleTime: 1000 * 60 * 5,
    });
  }, [
    enabled,
    data?.data.isLastPage,
    queryFn,
    queryKey,
    id,
    page,
    pagination.pageSize,
    sortOptions,
    filterOptions,
  ]);

  return {
    data: unwrappedData,
    error,
    isLoading,
    isFetching,
    isPreviousData,
  };
}
