import { getCommunityAssetAvailableRatesAndTimes } from "@App/api/amenities";
import {
  QUERY_KEY_USER_VENUE_SPACES_RATES,
  QUERY_KEY_USER_VENUES_RATES,
} from "@App/constants/queryKeyConstants";
import { useQuery } from "@tanstack/react-query";
import { formatISO } from "date-fns";

export function useAvailableRatesAndTimes({
  parentId,
  selectedDate,
}: {
  parentId?: string;
  selectedDate?: Date;
}) {
  if (!parentId || !selectedDate) {
    return { availableRates: undefined, isLoading: false };
  }

  const targetDate = formatISO(selectedDate, {
    format: "extended",
    representation: "date",
  });

  const { data: availableRates, isLoading: isLoadingRates } = useQuery({
    queryKey: [QUERY_KEY_USER_VENUES_RATES, parentId, targetDate],
    queryFn: () =>
      getCommunityAssetAvailableRatesAndTimes(parentId, targetDate),
    enabled: !!parentId,
    select: (data) => data.data,
  });

  return { availableRates, isLoadingRates };
}

export function useAvailableSpaceRatesAndTimes({
  parentId,
  selectedDate,
  spaceId,
}: {
  parentId: string;
  selectedDate?: Date;
  spaceId?: string;
}) {
  if (!selectedDate) {
    return { availableRates: undefined, isLoading: false };
  }

  const targetDate = formatISO(selectedDate, {
    format: "extended",
    representation: "date",
  });

  const { data: availableRates, isLoading: isLoadingRates } = useQuery({
    queryKey: [
      QUERY_KEY_USER_VENUE_SPACES_RATES,
      parentId,
      targetDate,
      spaceId,
    ],
    queryFn: () =>
      getCommunityAssetAvailableRatesAndTimes(
        parentId,
        targetDate,
        `Space/${spaceId}`,
      ),
    enabled: !!spaceId,
    select: (data) => data.data,
  });

  return { availableRates, isLoadingRates };
}
