import { useEffect, useState } from "react";

const useScreenView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () =>
    setIsMobileView(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isMobileView,
  };
};

export default useScreenView;
