import { getAmenitiesDetails } from "@App/api/amenities";
import userSession from "@App/auth/userSession";
import { SHOW_LOGIN_MODAL } from "@App/constants/appConstants";
import {
  QUERY_KEY_USER_RESERVATION_LIST,
  QUERY_KEY_USER_VENUE_DETAILS,
} from "@App/constants/queryKeyConstants";
import { SHOW_REGISTRATION_DETAILS } from "@App/constants/userConstants";
import { UserType } from "@App/models/types";
import { UserState } from "@App/store/reducers/userReducer";
import { PaymentsProps } from "@Components/payments/PaymentsPropTypes";
import { RootState } from "@Store/store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  customEventPublish,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const VenuesDetailLogic = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const params = useParams();
  const venueId = params.venueId ?? "";

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const userType = useMemo(() => userData.userInfo?.userType, [userData]);

  const dispatch = useDispatch();

  const [showVenueBookingPayment, setShowVenueBookingPayment] = useState(false);
  const [hasSpaceBooking, setHasSpaceBooking] = useState<boolean | null>(null);
  const [showVenueBooking, setShowVenueBooking] = useState(false);
  const [showReservationRules, setShowReservationRules] = useState(false);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  const [paymentData, setPaymentData] = useState<PaymentsProps>();

  const { data: communityAsset, isLoading: isLoadingCommunityAsset } = useQuery(
    {
      queryKey: [QUERY_KEY_USER_VENUE_DETAILS, venueId],
      queryFn: () => getAmenitiesDetails(venueId),
      enabled: !!venueId,
      select: (data) => data.data,
    },
  );

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoadingCommunityAsset });
  }, [isLoadingCommunityAsset]);

  useEffect(() => {
    if (communityAsset) {
      const hasReservableSpaces = !!communityAsset.spaces?.some(
        (sp) => sp.canBeBooked && !!sp.reservation?.setup,
      );
      setHasSpaceBooking(hasReservableSpaces);
      setShowVenueBooking(!hasReservableSpaces);
    }
  }, [communityAsset]);

  const goBack = () => {
    navigate(-1);
  };

  const bookingCtaLabel = useMemo(() => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        return "Proceed";
      }
      if (userType === UserType.Initiated) {
        return "Please Complete Account Setup";
      }
      if (userType === UserType.ResidentPending) {
        return "Resident Verification Pending";
      }
    }
    return "Log In to Proceed";
  }, [userType]);

  const goToVenueBookingPayment = (paymentData: PaymentsProps) => {
    setPaymentData(paymentData);
    setShowVenueBookingPayment(true);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  const handleCTAButton = (paymentData: PaymentsProps) => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        goToVenueBookingPayment(paymentData);
        return;
      }
      if (userType === UserType.Initiated) {
        showAccountDetails();
        return;
      }
      if (userType === UserType.ResidentPending) {
        return;
      }
    }

    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const onPaymentSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_USER_RESERVATION_LIST]);
  };

  return {
    communityAsset,
    isLoadingCommunityAsset,
    paymentData,

    bookingCtaLabel,
    handleCTAButton,

    isMobileEmbed,
    hasSpaceBooking,

    showVenueBookingPayment,
    setShowVenueBookingPayment,
    showReservationRules,
    setShowReservationRules,
    showVenueBooking,
    setShowVenueBooking,

    goBack,
    onPaymentSuccess,
  };
};

export default VenuesDetailLogic;
