import { Chevron } from "@Components/icons";
import { TrashIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import "./NotificationItem.scss";
import useNotificationItemLogic from "./NotificationItemLogic";
import { NotificationItemProps } from "./NotificationItemProps";

const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  const {
    notification,
    title,
    message,
    isUnread,
    canExpand,
    hasLink,
    isExpanded,
    setIsExpanded,
    handleDeleteAsync,
    handleOnClick,
  } = useNotificationItemLogic(props);

  const date = moment(notification.inserted_at).format("MMMM Do");

  return (
    <div
      className={`notification-item ${
        isUnread ? "notification-item__unread" : ""
      } ${hasLink ? "notification-item__link" : ""}`}
      onClick={handleOnClick}
    >
      <div
        className="notification-item__header"
        onClick={() => canExpand && setIsExpanded(!isExpanded)}
      >
        <h2 className="notification-item__title">{title}</h2>
        {canExpand && (
          <button
            className={`expand-btn notification-item__expand ${
              isExpanded ? "expanded" : ""
            }`}
          >
            <Chevron
              className={"expand-btn"}
              color="#323232"
              position="down"
              size={20}
            />
          </button>
        )}
        <span className="notification-item__date">{date}</span>
      </div>
      <div>
        <div
          className={`notification-item__body ${isExpanded ? "expanded" : ""}`}
        >
          {message}
        </div>
        <div className="notification-item__footer">
          {/*{notification.category && (
          <Tag text={notification.category} size="small" />
        )}*/}
          <div className="notification-item__footer__container">
            <TrashIcon
              color={"#9A9999"}
              className={"trash-icon notification-item__footer-delete-button"}
              width={20}
              height={20}
              onClick={handleDeleteAsync}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
