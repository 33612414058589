import { PromptsModal } from "@Components/index";
import EventUserPromptsLogic from "@Components/event-user-prompts/EventUserPromptsLogic";
import { EventUserPromptsProps } from "@Components/event-user-prompts/EventUserPromptsTypes";
import { Prompt } from "@App/models/prompt";
import usePrompts from "@App/hooks/api/prompts/usePrompts";

const EventUserPrompts = (props: EventUserPromptsProps) => {
  const { handlePromptsClose } = EventUserPromptsLogic(props);
  const prompts = usePrompts(props.promptIds || []);

  return (
    <PromptsModal
      prompts={prompts
        .map((p) => p.data?.data)
        .filter((p): p is Prompt => p !== undefined)}
      isVisible={props.isVisible}
      onContinue={handlePromptsClose}
    />
  );
};

export default EventUserPrompts;
