import { Loader, Modal, ToolTip } from "@Components/index";
import "./TextArea.scss";
import TextAreaLogic from "./TextAreaLogic";

export interface TextAreaProps {
  height: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  withMargin?: boolean;
  required?: boolean;
  value?: string;
  isValid?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  isLoadingContent?: boolean;
  tooltip?: string;
  tooltipPosition?: "left" | "top" | "right" | "bottom";
  informationModalContent?: React.ReactNode;
  pasteDisabled?: boolean;
  onChange: (value: string) => void;
  dataCy?: string;
  className?: string;
}

const TextArea = ({
  label = "",
  height,
  placeholder,
  maxLength,
  withMargin,
  required = false,
  value,
  isValid = true,
  errorMessage,
  isDisabled,
  isLoadingContent,
  tooltip,
  tooltipPosition = "left",
  informationModalContent,
  pasteDisabled = false,
  onChange,
  dataCy,
  className = "",
}: TextAreaProps) => {
  const { showinformationModal, setShowinformationModal } = TextAreaLogic();
  return (
    <div
      className={`text-area ${withMargin && "text-input-margin"} ${className}`}
    >
      <div className={"text-area-label-row"}>
        <div className={"text-area-label"}>{`${label}${
          required ? "*" : ""
        }`}</div>

        {(!!tooltip || informationModalContent) && (
          <ToolTip
            onClick={() => setShowinformationModal(true)}
            tooltipText={tooltip}
            tooltipPosition={tooltipPosition}
          />
        )}
      </div>

      {isLoadingContent ? (
        <div className={"text-area-field text-input-loader"}>
          <Loader modal={false} color={"silver"} />
        </div>
      ) : (
        <textarea
          className={`text-area-field`}
          style={{ height: height }}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          disabled={isDisabled}
          onPaste={(e) => {
            if (pasteDisabled) {
              e.preventDefault();
            }
          }}
          data-cy={dataCy}
        />
      )}

      {!isValid && (
        <label className={"text-area-error"} data-cy={dataCy + "-error"}>
          {errorMessage}
        </label>
      )}
      <Modal
        isVisible={showinformationModal}
        onClose={() => {
          setShowinformationModal(false);
        }}
        children={informationModalContent}
      />
    </div>
  );
};

export default TextArea;
