import { useCallback, useEffect, useState } from "react";
import {
  GenericListingRowItem,
  GenericListingRowLogicProps,
} from "./GenericListingPropTypes";
import { QueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { QUERY_KEY_GENERIC_ROW_ITEMS } from "@App/constants/queryKeyConstants";

const GenericListingRowLogic = ({
  listingId,
  getCategoryItems,
  searchText,
  canBeBooked,
  getFilterRequestParams,
  searchFilters,
  moduleName,
  onListLoaded,
  onListEmpty,
}: GenericListingRowLogicProps) => {
  const [items, setItems] = useState<GenericListingRowItem[]>([]);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const searchFiltersLength = searchFilters?.length ?? 0;

  useEffect(() => {
    const queryClient = new QueryClient();
    queryClient.invalidateQueries([QUERY_KEY_GENERIC_ROW_ITEMS, moduleName]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      QUERY_KEY_GENERIC_ROW_ITEMS,
      moduleName,
      [
        getCategoryItems,
        listingId,
        searchText,
        searchFilters,
        searchFiltersLength,
        getFilterRequestParams,
        canBeBooked,
      ],
    ],
    queryFn: ({ pageParam }) => {
      let tempItems: GenericListingRowItem[] =
        pageParam && pageParam > 1 ? [...items] : [];
      let categorySearchObject: any = {
        listingCategoryIds: listingId,
        page: pageParam ?? 1,
      };
      if (searchText) {
        categorySearchObject.title = searchText;
      }
      if (canBeBooked === true) {
        categorySearchObject.canBeBooked = canBeBooked;
      }
      if (searchFilters && getFilterRequestParams) {
        categorySearchObject = {
          ...categorySearchObject,
          ...getFilterRequestParams(searchFilters),
        };
      }
      return getCategoryItems(categorySearchObject).then((res) => {
        res.data?.items?.forEach((item: any) => {
          tempItems.push({
            title: item.title,
            files: [{ url: item.coverImageUrl }],
            id: item.id,
          });
        });
        setItems(tempItems);
        setIsLoadingItems(false);
        onListLoaded && onListLoaded();
        tempItems.length === 0 && onListEmpty && onListEmpty();
        return res.data;
      });
    },
    staleTime: 0,
    cacheTime: 1000,
    getNextPageParam: (lastPage) => {
      if (lastPage.isLastPage) {
        return;
      } else {
        return lastPage.page + 1;
      }
    },
  });

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "100px",
  });

  const onIntersect = useCallback(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    onIntersect();
  }, [onIntersect]);

  return {
    items,
    isLoadingItems,
    hasNextPage,
    ref,
  };
};

export default GenericListingRowLogic;
