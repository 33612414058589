import {
  DEFAULT_MOBILE_MEDIA_QUERY,
  RSVP_LOGIN_FLOW,
} from "@App/constants/appConstants";
import { Bars } from "@Components/icons";
import {
  ActionBanner,
  Button,
  Image,
  Loader,
  Menu,
  Modal,
  NavbarItems,
  RegistrationDetails,
  ResendEmail,
  ResultBanner,
  UserMenu,
} from "@Components/index";
import { Footer } from "@Layouts/index";
import { Login, Registration } from "@Pages/index";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import NavbarLogic from "./NavbarLogic";
import { NavbarProps } from "./NavbarPropTypes";

import themedStyles from "@Assets/styles/themedStyles.scss";
import useBannerDetails from "@App/hooks/api/banners/useBannerDetails";
import NotificationPopover from "@Components/notifications-popover/NotificationPopover";
import {
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const ICONS_SIZE = 25;

const Navbar = (componentProps: NavbarProps) => {
  const props = NavbarLogic();
  const navigate = useNavigate();

  const { banner, bannerFeatureEnabled } = useBannerDetails();

  const cmsBannerComponent = banner?.isEnabled && (
    <ActionBanner
      html={banner?.content}
      backgroundColor={banner?.backgroundColor}
      fontColor={banner?.fontColor}
      customIcon={
        <img
          className={"cms-main__icon"}
          alt={""}
          src={props.tenantConfig?.cmsSettings.websiteSettings.communityIcon}
        />
      }
    />
  );

  return (
    <>
      {!props.isMobileEmbed && bannerFeatureEnabled && banner && (
        <>
          {
            !props.isLogged && cmsBannerComponent // render cms banner
          }
          {!props.navigationMenuVisible &&
            props.isLogged &&
            !props.isEmailVerified && (
              <ActionBanner
                text={"Please check your email to verify your account."}
                noUnderline
                actionLinkText={"Click here to resend"}
                actionClick={() => {
                  props.setShowResendEmailModal(true);
                }}
              />
            )}
          {!props.navigationMenuVisible &&
            props.isLogged &&
            props.isEmailVerified &&
            !props.isResidentialStatusProvided && (
              <ActionBanner
                text={
                  "Account Validation Required: Please click here to ensure your account setup is complete!"
                }
                onClick={() => {
                  window.history.replaceState(
                    null,
                    "",
                    window.location.pathname,
                  );
                  props.showAccountDetails();
                }}
                backgroundColor={themedStyles.actionBannerAlternativeBackground}
                fontColor={themedStyles.actionBannerAlternativeText}
              />
            )}
          {
            !props.navigationMenuVisible &&
              props.isLogged &&
              props.isEmailVerified &&
              props.isResidentialStatusProvided &&
              cmsBannerComponent // render cms banner
          }
        </>
      )}

      <div className={"nav-container"}>
        <div
          className={`nav-wrapper ${
            props.isMobileEmbed ? "nav-wrapper-hidden" : ""
          }`}
          onClick={() => {
            // setIsUserMenuVisible(false);
          }}
        >
          <div className="nav">
            {/*Logo link to root*/}
            <div className={"nav-logo-container"}>
              <Link
                to={"/"}
                className={"nav-logo-link"}
                onClick={() => {
                  props.setIsUserMenuVisible(false);
                }}
              >
                <Image
                  className="nav-logo-link-icon"
                  src={
                    props.tenantConfig?.cmsSettings?.websiteSettings
                      ?.communityIcon
                  }
                  alt="Logo Icon"
                  width="40px"
                  height="40px"
                />
                <Image
                  className="nav-logo-link-banner"
                  src={
                    props.tenantConfig?.cmsSettings?.websiteSettings
                      ?.communityLogo
                  }
                  alt="Logo Banner"
                  width="173px"
                  height="40px"
                />
              </Link>
            </div>

            {/* Desktop view */}
            <div className={"nav-menu-items-center"}>
              <NavbarItems />
            </div>

            {/* Mobile view and right buttons. TODO: Should work like the view above */}
            <div
              className={`nav-menu-items-right ${
                props.navigationMenuVisible ? "visible" : ""
              }`}
            >
              {props.isLogged && (
                <div className={"user-welcome"}>
                  {`Hello ${props.userInfo?.firstName ?? ""}`}
                </div>
              )}
              <div className={"mobile-items"}>
                <NavbarItems />
              </div>

              <div className={"main-items"}>
                {/*  Location icon */}
                <Link className={"nav-button-icon-link"} to={"/locations"}>
                  <MapPinIcon
                    width={ICONS_SIZE}
                    height={ICONS_SIZE}
                    color={
                      props.currentRoute === "/locations"
                        ? themedStyles.navigationIconActiveColor
                        : themedStyles.navigationIconColor
                    }
                  />
                  <span className={"nav-button-icon-link-text"}>Map</span>
                </Link>

                {/*  Calendar icon */}
                <Link className={"nav-button-icon-link"} to={"/calendar"}>
                  <CalendarDaysIcon
                    color={
                      props.currentRoute === "/calendar"
                        ? themedStyles.navigationIconActiveColor
                        : themedStyles.navigationIconColor
                    }
                    width={ICONS_SIZE}
                    height={ICONS_SIZE}
                  />
                  <span className={"nav-button-icon-link-text"}>Events</span>
                </Link>

                {props.isLogged && (
                  <div className={"nav-user-actions"}>
                    {/*  Notifications IconLink & Modal*/}
                    <NotificationPopover
                      onMobileActionTaken={() => {
                        props.setNavigationMenuVisible(false);
                        navigate("/notifications");
                      }}
                      onPopoverActivated={() => {
                        props.setIsUserMenuVisible(false);
                      }}
                    />

                    {/*  User Profile IconLink & Modal*/}
                    <Link
                      className={"nav-button-icon-link"}
                      to="/profile"
                      onClick={(e) => {
                        if (
                          !window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches
                        ) {
                          e.preventDefault();
                        }
                        if (props.isLogged) {
                          props.setIsUserMenuVisible(!props.isUserMenuVisible);
                        } else {
                          localStorage.removeItem(RSVP_LOGIN_FLOW);
                          props.setShowLogin(true);
                        }
                      }}
                    >
                      <UserIcon
                        color={
                          props.currentRoute === "/profile"
                            ? themedStyles.navigationIconActiveColor
                            : themedStyles.navigationIconColor
                        }
                        width={ICONS_SIZE}
                        height={ICONS_SIZE}
                      />
                      <span className={"nav-button-icon-link-text"}>
                        Profile & Settings
                      </span>
                    </Link>
                    {props.isUserMenuVisible && (
                      <Menu
                        className={"nav-user-menu-modal"}
                        backgroundColor={"#fff"}
                        fixedPosition={"right"}
                      >
                        <UserMenu
                          firstname={props.userInfo?.firstName ?? ""}
                          loyaltyPoints={1000}
                          onLogout={props.handleLogout}
                          dataCy="logout-button"
                        />
                      </Menu>
                      // </div>
                    )}
                    <Button
                      className={"mobile-logout"}
                      text={"Log Out"}
                      isSecondary
                      onClick={props.handleLogout}
                    />
                  </div>
                )}

                {/*  Auth section, we should wrap all this section with the new tenant prop */}
                {!props.isLogged && !props.areCommunityUserAccountsDisabled && (
                  <div className={"nav-user-actions"}>
                    <Button
                      text={"LOG IN"}
                      className={"nav-user-access-button"}
                      onClick={() => {
                        props.setNavigationMenuVisible(false);
                        if (props.isLogged) {
                          props.setIsUserMenuVisible(!props.isUserMenuVisible);
                        } else {
                          localStorage.removeItem(RSVP_LOGIN_FLOW);
                          props.setShowLogin(true);
                        }
                      }}
                      data-cy="log-in-button"
                      isSecondary={
                        !window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches
                      }
                    />

                    <Button
                      text={"SIGN UP"}
                      className={"nav-user-access-button"}
                      isSecondary
                      onClick={() => {
                        props.setShowRegistration(true);
                      }}
                      data-cy="sign-up-button"
                    />
                  </div>
                )}
                {props.thirdPartyNavbar && (
                  <div className={"nav-user-actions"}>
                    <Button
                      text={
                        props.thirdPartyNavbar?.displayText?.toUpperCase() ??
                        props.thirdPartyNavbar?.displayText
                      }
                      className={"nav-user-access-button"}
                      onClick={() => {
                        window.open(props.thirdPartyNavbar?.url, "_blank");
                      }}
                      isSecondary
                    />
                  </div>
                )}
              </div>
            </div>
            <Button
              onClick={() => {
                props.setNavigationMenuVisible(!props.navigationMenuVisible);
              }}
              className={"nav-mobile-menu-button"}
              icon={<Bars color={themedStyles.navigationIconColor} />}
            />
          </div>
        </div>
        {!props.navigationMenuVisible && (
          <div
            className={"nav-content-container"}
            onClick={() => {
              props.setIsUserMenuVisible(false);
            }}
          >
            {/* Content */}
            <div className={`nav-content`}>
              {props.showResultBanner && props.isLogged && (
                <ResultBanner
                  text={props.resultBannerMessage}
                  onClose={() => {
                    props.hideResultBanner();
                  }}
                />
              )}
              {componentProps.children}
            </div>
          </div>
        )}
      </div>
      {!props.isMobileEmbed && !props.navigationMenuVisible && <Footer />}
      {(props.isProcessing ||
        props.isloadingUserData ||
        !props.tenantConfig) && <Loader text={props.processingMessage} modal />}
      <ResendEmail
        isVisible={props.showResendEmailModal}
        onClose={() => {
          props.setShowResendEmailModal(false);
        }}
      />
      <Modal
        isVisible={props.showLogin && !props.areCommunityUserAccountsDisabled}
        onClose={() => {
          props.setShowLogin(false);
        }}
      >
        <Login
          errorMessage={props.loginErrorMessage}
          onClose={(showRegistration) => {
            props.setShowLogin(false);
            if (showRegistration) {
              props.setShowRegistration(true);
            }
          }}
        />
      </Modal>
      <Modal
        isVisible={
          props.showRegistration && !props.areCommunityUserAccountsDisabled
        }
        onClose={() => {
          props.handleRegistrationModalClose();
        }}
      >
        <Registration
          onClose={(showLogin) => {
            props.handleRegistrationModalClose(showLogin);
          }}
          duplicateAccountError={props.isDuplicateAccountError}
          userData={props.userDataParameters}
        />
      </Modal>
      <Modal
        isVisible={
          props.showRegistrationUserDetails &&
          !props.areCommunityUserAccountsDisabled
        }
      >
        <RegistrationDetails
          tenantName={props.tenantConfig?.name}
          onSave={props.onRegistrationDetailsSave}
          onClose={props.onRegistrationDetailsClose}
        />
      </Modal>
    </>
  );
};

export default Navbar;
