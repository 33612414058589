import React, { PropsWithChildren } from "react";
import { useKnockFeed } from "@knocklabs/react-core";
import { ChevronDown } from "@knocklabs/react";

export type DropdownProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const NotificationFeedDropdown: React.FC<
  PropsWithChildren<DropdownProps>
> = ({ children, value, onChange }) => {
  const { colorMode } = useKnockFeed();

  return (
    <div className={`rnf-dropdown rnf-dropdown--${colorMode}`}>
      <select
        aria-label="Select notification filter"
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
      <ChevronDown />
    </div>
  );
};
