import { useTenantConfig } from "@App/hooks";
import { Space } from "@App/models/amenity";
import { Button } from "@Components/index";

export function VenuesSpaceCard({
  space,
  onClick,
}: {
  space: Space;
  onClick: () => void;
}) {
  const placeholderUrl = useTenantConfig()?.features.defaultImagePlaceholder;
  const { name, files, description } = space;

  return (
    <div className={"venues-spaces-items-item"}>
      <div className={"venues-spaces-items-item-left"}>
        <div className={"venues-spaces-items-item-title"}>{name}</div>
        <img
          src={files && files.length > 0 ? files[0].url : placeholderUrl ?? ""}
          alt={`${name}`}
          className="venues-spaces-items-item-image"
        />
        <div
          className={"venues-spaces-items-item-description"}
          dangerouslySetInnerHTML={{
            __html: description ?? "",
          }}
        />
        <div className={"venues-spaces-items-item-button"}>
          <Button
            text={"Check Dates and Rates"}
            onClick={onClick}
            isSecondary
          />
        </div>
      </div>
    </div>
  );
}
