import { DEFAULT_PAGE_SIZE } from "@App/constants/pageParamConstants";
import {
  AxiosGenericPagedResponse,
  GenericPageItem,
} from "@App/models/BasePagedResponse";
import {
  AccessorKeyColumnDef,
  Column,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { MemoizedTableBody, TableBody } from "./TableBody";
import useTablePagedQuery from "./useTablePagedQuery";
import "./Table.scss";
import PaginationControlsV2 from "@Components/table/PaginationControlsV2";
import { isEqual } from "lodash";
import { Button } from "@Components/shadcn/button/Button";
import {
  BaseTableRequest,
  DEFAULT_RESIZE_SPEED,
  TableSortOptions,
} from "@Components/table/types";
import Loader from "@Components/loader/Loader";
import { ArrowDownIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";

type TableProps<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
> = {
  // Devs haven't fixed the type of columnDef yet
  columns: ColumnDef<GenericPageItem<TData>, any>[];
  exportColumns?: ColumnDef<GenericPageItem<TData>, any>[];
  queryKey: string[];
  queryFn: (
    request: BaseTableRequest<TSortOptions, TFilterOptions>,
  ) => Promise<AxiosGenericPagedResponse<TData>>;
  filterOptions?: TFilterOptions;
  rowOnClick?: (row: Row<GenericPageItem<TData>>) => void;
  rowOnClickLinkTo?: (row: Row<GenericPageItem<TData>>) => string;
  highlightOnHover?: boolean;
  enabled?: boolean;
  resizeTrigger?: boolean;
  id?: string;
  containerWidth?: number;
  resizeSpeed?: number;
  emptyResultMessage?: string;
  exportReportName?: string;
};

function renderHeader<T>(column: Column<GenericPageItem<T>>) {
  if (!column.columnDef.enableSorting) {
    return column.columnDef.header;
  }

  // header can be a string or `(info)=>jsxElement`, get val based on type
  const headerVal = (info: any) =>
    typeof column.columnDef.header === "string"
      ? column.columnDef.header
      : (column.columnDef.header as any)(info); // trust me bro

  return (info: any) => (
    <Button
      variant={"ghost"}
      onClick={() => {
        // a columns sort can be in one of three states: disabled, ascend sort, and descend sort
        // the following logic decides the next sort state to go into based on the previous
        // disabled -> asc -> desc -> disabled
        const currentSort = column.getIsSorted();
        const nextSortState =
          currentSort === false // if no toggling enabled
            ? false // move to 'asc' sorting
            : currentSort === "asc" // if asc enabled
              ? true // move to 'desc' sorting
              : undefined; // move to 'disabled' sorting
        column.toggleSorting(nextSortState, column.columnDef.enableMultiSort);
      }}
    >
      <p className={"font-bold text-center uppercase text-[16px]"}>
        {headerVal(info)}
      </p>
      {column.getIsSorted() === false && ( // if sorting isnt enabled
        <ArrowsUpDownIcon color={"#717171"} className="ml-2 h-4 w-4 -mt-1" />
      )}
      {column.getIsSorted() === "asc" && ( // if sorting asc
        <ArrowsUpDownIcon color={"#000000"} className="ml-2 h-4 w-4 -mt-1" />
      )}
      {column.getIsSorted() === "desc" && ( // if sorting desc
        <ArrowDownIcon color={"#000000"} className="ml-2 h-4 w-4 -mt-1" />
      )}
    </Button>
  );
}

export default function Table<
  TData,
  TSortOptions extends TableSortOptions<TData>,
  TFilterOptions,
>({
  columns,
  exportColumns,
  queryKey,
  queryFn,
  filterOptions,
  rowOnClick,
  rowOnClickLinkTo,
  highlightOnHover,
  enabled,
  resizeTrigger,
  id,
  emptyResultMessage,
  containerWidth = 100, // In percentage
  resizeSpeed = DEFAULT_RESIZE_SPEED, // Larger number means slower resizing
  exportReportName,
}: TableProps<TData, TSortOptions, TFilterOptions>) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // check for filter or sorting changes to reset page
  const prevFilterOptionsRef = useRef(filterOptions);
  const prevSortingRef = useRef(sorting);
  useEffect(() => {
    if (!isEqual(filterOptions ?? {}, prevFilterOptionsRef.current ?? {})) {
      // if filters changed reset page to 0
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
      prevFilterOptionsRef.current = filterOptions;
    }
    if (!isEqual(sorting ?? {}, prevSortingRef.current ?? {})) {
      // if sorting changed reset page to 0
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
      prevSortingRef.current = sorting;
    }
  }, [filterOptions, sorting]);

  // this function creates a property->sortType key-value object for the sort query
  const mapSortOptionsForQuery = () =>
    sorting.reduce((finalObj: any, sort) => {
      // find the column definition by accessor key
      const columnDef = columns.find(
        (col) =>
          (col as AccessorKeyColumnDef<any, any>).accessorKey === sort.id,
      );
      // find if there's a specific sort property to use
      const sortId = columnDef?.meta?.columnSortPropertyName ?? sort.id;
      finalObj[sortId] = sort.desc;
      return finalObj;
    }, {} as TSortOptions);

  const { data, error, isLoading, isFetching, isPreviousData } =
    useTablePagedQuery<TData, TSortOptions, TFilterOptions>({
      queryKey,
      queryFn,
      id,
      pagination,
      sortOptions: mapSortOptionsForQuery(),
      filterOptions,
      enabled,
    });
  // column sizing works by adding all column sizes together, and assigning column widths based on the column's size against the total size
  // this allows for simple and dynamic sizing with very granular control
  const totalSize = columns
    .map((c) => c.size!)
    .reduce((acc, cur) => acc + cur, 0);

  // set size of columns to percentage
  for (const column of columns) {
    column.size = (column.size! / totalSize) * 100 * resizeSpeed;
  }

  const table = useReactTable({
    data: data?.items ?? [],
    columns,
    defaultColumn: {
      // Sizes are set as percentages for responsiveness
      // Resize speed factor slows down resizing by allowing for more values per pixel change
      minSize: columns?.length ? ((100 / columns.length) * resizeSpeed) / 2 : 0,
      // size: 25 * resizeSpeed,
      size: columns?.length ? (100 / columns.length) * resizeSpeed : 0,
      maxSize: 75 * resizeSpeed,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    rowCount: data?.totalItemsCount,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  // Use CSS vars to store sizes, this allows us to calc all the widths at once and memoize them.
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: string } = {};

    for (const header of headers) {
      const headerSize = `${header.getSize() / resizeSpeed}%`;
      const cellSize = `${header.column.getSize() / resizeSpeed}%`;

      colSizes[`--header-${header.id}-size`] = headerSize;
      colSizes[`--col-${header.column.id}-size`] = cellSize;
    }
    return colSizes;
  }, [
    table.getState().columnSizingInfo,
    table.getState().columnSizing,
    isLoading,
    resizeTrigger,
  ]);

  const hasData = data?.items && data.items.length > 0;

  return (
    <div className={"px-4"} style={{ width: `${containerWidth}%` }}>
      <table
        className={"table"}
        style={{
          ...columnSizeVars,
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="table-header-row">
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="table-header-row-ele"
                    style={{
                      width: `var(--header-${header?.id}-size)`,
                    }}
                  >
                    <p
                      className={`table-header-row-ele-content ${
                        header.column.columnDef.meta?.centerAligned
                          ? "table-header-row-ele-content-center"
                          : "table-header-row-ele-content-left"
                      } ${
                        header.column.columnDef.enableSorting
                          ? "table-header-row-ele-content-sorting"
                          : ""
                      }`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            renderHeader(header.column),
                            header.getContext(),
                          )}
                    </p>
                    {header.index !== headerGroup.headers.length && (
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className="table-header-row-ele-resizer"
                      >
                        <div
                          // TODO: Hover effect doesn't work
                          className="table-header-row-ele-resizer-line"
                          style={{
                            backgroundColor: header.column.getIsResizing()
                              ? "rgba(0,0,0,0.5)"
                              : "rgb(177,177,177)",
                          }}
                        ></div>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {(isLoading || isFetching) && (
          <div className="table-loader">
            <div className="table-loader-bg"></div>
            <Loader className="w-12" />
          </div>
        )}
        {
          // handle rendering no data
          !isLoading && !hasData && (
            <div className="text-center">
              {emptyResultMessage ?? "No data found"}
            </div>
          )
        }
        {
          // handle rendering table with data
          hasData && table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody
              table={table}
              resizeSpeed={resizeSpeed}
              highlightOnHover={highlightOnHover}
              onClick={rowOnClick}
            />
          ) : (
            <TableBody
              table={table}
              resizeSpeed={resizeSpeed}
              highlightOnHover={highlightOnHover}
              onClick={rowOnClick}
              onClickLinkTo={rowOnClickLinkTo}
            />
          )
        }
      </table>
      {hasData && (
        <PaginationControlsV2 table={table} isFetching={isFetching} />
      )}
    </div>
  );
}
