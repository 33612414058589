import { useSelector } from "react-redux";
import { RootState } from "@App/store/store";

import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_USER_DETAILS } from "@App/constants/queryKeyConstants";
import { getUserAccountInfo } from "@App/api/user";
import { AccountInfo, UserResponse } from "./ProfilePropTypes";
import { useState } from "react";
import MyAccount from "@Pages/profile/my-account/MyAccount";
import MyEvents from "@Pages/profile/my-events/MyEvents";
import MyReservations from "@Pages/profile/my-reservations/MyReservations";
import MyMemberships from "@Pages/profile/my-memberships/MyMemberships";

const ProfileLogic = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const userProfile: UserResponse = useSelector(
    (state: RootState) => state.userProfile,
  );
  const userId = userProfile?.userInfo?.id;

  const { data: accountInfo, isLoading } = useQuery(
    [QUERY_KEY_USER_DETAILS, userId],
    () => {
      return getUserAccountInfo(userId).then((res) => res.data as AccountInfo);
    },
    {
      enabled: !!userId,
    },
  );

  const tabs = [
    {
      id: "account",
      label: "My Account",
      isActive: selectedTabIndex === 0,
      comp: <MyAccount accountInfo={accountInfo} isLoading={isLoading} />,
    },
    {
      id: "events",
      label: "My Events",
      isActive: selectedTabIndex === 1,
      comp: <MyEvents />,
    },
    {
      id: "reservations",
      label: "My Reservations",
      isActive: selectedTabIndex === 2,
      comp: <MyReservations />,
    },
    {
      id: "memberships",
      label: "My Memberships",
      isActive: selectedTabIndex === 3,
      comp: <MyMemberships />,
    },
  ];

  const selectedTab = tabs[selectedTabIndex];
  const handleTabChange = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  return {
    selectedTab,
    accountInfo,
    isLoading,
    tabs,
    handleTabChange,
  };
};

export default ProfileLogic;
