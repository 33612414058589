import { CLOSE_INFORMATION_MODAL } from "@App/constants/appConstants";
import { customEventSubscribe, customEventUnsubscribe } from "@Utils/utils";
import { useEffect, useState } from "react";

const TextAreaLogic = () => {
  const [showinformationModal, setShowinformationModal] = useState(false);

  useEffect(() => {
    customEventSubscribe(CLOSE_INFORMATION_MODAL, () =>
      setShowinformationModal(false),
    );
    return () => {
      customEventUnsubscribe(CLOSE_INFORMATION_MODAL, () =>
        setShowinformationModal(false),
      );
    };
  }, []);
  return {
    showinformationModal,
    setShowinformationModal,
  };
};

export default TextAreaLogic;
